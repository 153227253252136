import React, { useState } from "react";
import styles from "./ControlDialog.module.css";
import socketService from "services/socketService";
import { useHistory } from "react-router";
let ipcRenderer;

try {
  ipcRenderer = window?.require("electron").ipcRenderer;
} catch (error) {
  console.error("Failed to require electron's ipcRenderer:", error);
}

// const FastSpeedtest = require("fast-speedtest-api");
export const ControlDialog = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [enteredPin, setEnteredPin] = useState("");
  const [isPinCorrect, setIsPinCorrect] = useState(false);
  const history = useHistory();
  // const [wifiSpeed, setWifiSpeed] = useState(null);
  // const performSpeedTest = () => {
  //   return new Promise((resolve, reject) => {
  //     let speedtest = new FastSpeedtest({
  //       token: "YXNkZmFzZGxmbnNkYWZoYXNkZmhrYWxm",
  //       verbose: false,
  //       timeout: 5000,
  //       https: true,
  //       urlCount: 5,
  //       bufferSize: 8,
  //       unit: FastSpeedtest.UNITS.Mbps,
  //     });

  //     speedtest
  //       .getSpeed()
  //       .then((s) => {
  //         setWifiSpeed(s);
  //       })
  //       .catch((e) => {
  //         console.error("Speed test error", e.message);
  //       });
  //   });
  // };

  // useEffect(() => {
  //   // performSpeedTest();
  //   const interval = setInterval(
  //     () => {
  //       performSpeedTest();
  //     },
  //     5 * 60 * 1000
  //   );

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  const correctPin = "3678";
  const closePin = "3679";
  const closeKiosk = () => {
    ipcRenderer?.send("close-kiosk");
  };
  const handleReload = () => {
    handleClose();
    history.push({
      pathname: "/",
      state: { action: "reload" },
    });
  };

  const handleWifiConfig = () => {
    handleClose();
    history.push({
      pathname: "/wifiConfig",
    });
  };
  const handleShutdown = () => {
    socketService.shutdown();
  };
  const handleReboot = () => {
    socketService.reboot();
  };
  const handleClose = () => {
    setDialogOpen(false);
    setIsPinCorrect(false);
    setEnteredPin("");
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const handleOverlayClick = (e) => {
    handleClose();
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handlePinClick = (value) => {
    if (value === "Clear") {
      setEnteredPin("");
    } else if (value === "Cancel") {
      handleClose();
    } else {
      const newPin = enteredPin + value;
      setEnteredPin(newPin);
      if (newPin === correctPin) {
        setIsPinCorrect(true);
      } else if (newPin === closePin) {
        closeKiosk();
      }
    }
  };

  return (
    <div
      style={{
        position: "relative",
        zIndex: 1000,
      }}
    >
      <div className={styles.icon} onClick={openDialog} draggable>
        <img
          src={process.env.PUBLIC_URL + "/images/icons8-settings.svg"}
          alt="Settings"
          className={styles.settingsIcon}
        />
      </div>
      {isDialogOpen && (
        <div className={styles.overlay} onClick={handleOverlayClick}>
          <div className={styles.settingsCointainer}>
            <img
              style={{
                height: "20px",
                width: "20px",
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
              alt=""
              onClick={handleClose}
              src={process.env.PUBLIC_URL + "/images/close.png"}
            ></img>
            {!isPinCorrect ? (
              <div className={styles.numpadContainer} onClick={stopPropagation}>
                <h2>
                  Enter PIN:{" "}
                  {enteredPin
                    .split("")
                    .map(() => "*")
                    .join("")}
                </h2>
                <div className={styles.numpad}>
                  {[
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "Clear",
                    "0",
                    "Cancel",
                  ].map((num) => (
                    <button
                      key={num}
                      className={styles.numpadButton}
                      onClick={() => handlePinClick(num)}
                    >
                      {num}
                    </button>
                  ))}
                </div>
              </div>
            ) : (
              <div className={styles.buttonContainer} onClick={stopPropagation}>
                {/* {wifiSpeed !== null && (
                  <div className={styles.wifiSpeed}>
                    WiFi Speed: {wifiSpeed} Mbps
                  </div>
                )} */}
                <button className={styles.button} onClick={handleReload}>
                  Home (Refresh)
                </button>
                <button className={styles.button} onClick={handleWifiConfig}>
                  WiFi Settings
                </button>
                <button className={styles.button} onClick={handleShutdown}>
                  Turn Off
                </button>
                <button className={styles.button} onClick={handleReboot}>
                  Restart
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
