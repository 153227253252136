class AudioGuideService {
  constructor() {
    this.sounds = {
      adjust_camera_height: new Audio(
        process.env.PUBLIC_URL + "/audios/adjust_camera_height.mp3",
      ),
      FRONT: new Audio(process.env.PUBLIC_URL + "/audios/front.mp3"),
      UPPER: new Audio(process.env.PUBLIC_URL + "/audios/upper.mp3"),
      LOWER: new Audio(process.env.PUBLIC_URL + "/audios/lower.mp3"),
      hold_timer: new Audio(process.env.PUBLIC_URL + "/audios/hold_timer.wav"),
      camera_click: new Audio(
        process.env.PUBLIC_URL + "/audios/camera_click.wav",
      ),
      stay_still: new Audio(process.env.PUBLIC_URL + "/audios/stay_still.mp3"),
    };
  }

  loadSounds(soundMapping) {
    for (const key in soundMapping) {
      this.sounds[key] = new Audio(process.env.PUBLIC_URL + soundMapping[key]);
    }
  }

  async stopAllSounds() {
    for (const key in this.sounds) {
      try {
        await this.stopSound(key);
      } catch (error) {
        console.log(error);
      }
    }
  }

  async playSound(key) {
    await this.stopAllSounds();
    if (this.sounds[key]) {
      await this.sounds[key].play();
    }
  }

  async stopSound(key) {
    if (this.sounds[key]) {
      await this.sounds[key].pause();
      this.sounds[key].currentTime = 0;
    }
  }

  registerSound(key, url) {
    this.sounds[key] = new Audio(url);
  }
}

export default new AudioGuideService();
