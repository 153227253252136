import ManualAdjustButtons from "containers/ManualAdjustArrow/ManualAdjust";
import React, { useState, useEffect } from "react";
import styles from "./style.module.css";
import { Grid } from "@material-ui/core";
import CameraPreview from "containers/CameraPreview/cameraPreview";
import socketService from "services/socketService";
import audioGuide from "services/audioGuide";
import { useHistory } from "react-router";
import { labelsMap } from "utils/i18n";
import { getLanguage } from "store/rootReducer";
import { getAutoHeightAdjust } from "store/rootReducer";
import { useSelector } from "react-redux";
import { getTestId } from "store/rootReducer";
import { getLocation } from "store/rootReducer";
import { getUserDetails } from "store/rootReducer";
import { getToken } from "store/rootReducer";
import axios from "axios";
import { addTaskwithDiseases } from "services/rootService";
export default function ScanAngles() {
  const history = useHistory();

  let capturingTimer;
  const [currentAngle, setCurrentAngle] = useState("front");
  const language = useSelector(getLanguage);
  const autoHeightAdjust = useSelector(getAutoHeightAdjust);
  const testId = useSelector(getTestId);
  const location = useSelector(getLocation);
  const userDetails = useSelector(getUserDetails);
  const authToken = useSelector(getToken);
  const { FRONT_TEETH, UPPER_TEETH, LOWER_TEETH } = labelsMap[language];

  useEffect(() => {
    console.log("AUTO height adjustment", autoHeightAdjust);
    if (!autoHeightAdjust) {
      audioGuide.playSound("adjust_camera_height");
      socketService.initiate(
        userDetails.name,
        userDetails.mobile,
        authToken,
        autoHeightAdjust,
        location,
        testId
      );
    }
    setTimeout(() => socketService.detectJaw(), autoHeightAdjust ? 1000 : 5000);
    socketService.on("currentAngle", handleCurrentAngle);
    socketService.on("scanComplete", handleScanComplete);

    socketService.on("hold", handleHoldAngle);
    socketService.on("capture", handleCapture);
    socketService.on("imageUpload", handleFileUpload);
    // socketService.adjustHeight();

    // Cleanup when component is unmounted
    return () => {
      socketService.removeListener("currentAngle", handleCurrentAngle);
      socketService.removeListener("scanComplete", handleScanComplete);

      socketService.removeListener("hold", handleHoldAngle);
      socketService.removeListener("capture", handleCapture);
      socketService.removeListener("imageUpload", handleFileUpload);
    };
  }, []);

  const getCurrentJawAngle = () => {
    return currentAngle;
  };

  const handleFileUpload = async (data) => {
    try {
      const blob = new Blob([new Uint8Array(data.byteObject)], {
        type: "image/jpg",
      });
      await addTaskwithDiseases(authToken, testId, data.angle, blob);
    } catch (error) {
      console.log("Something went wrong while uploading file", error);
    }
  };

  const handleCurrentAngle = async (angle) => {
    await audioGuide.playSound(angle.toUpperCase());
    setCurrentAngle(angle);
  };
  const handleHoldAngle = () => {
    audioGuide.playSound("stay_still");
    if (capturingTimer) {
      clearInterval(capturingTimer);
      capturingTimer = null;
    }
    capturingTimer = setInterval(() => {
      audioGuide.playSound("hold_timer");
    }, 1000);
  };

  const handleCapture = () => {
    if (capturingTimer) {
      clearInterval(capturingTimer);
      capturingTimer = null;
    }
    audioGuide.playSound("camera_click");
  };

  const handleScanComplete = () => {
    history.push("/report");
  };
  return (
    <>
      <div className={styles["manual-height"]}>
        {autoHeightAdjust && <ManualAdjustButtons showTextGuide={false} />}
      </div>
      <Grid className={styles["grid"]} container>
        <Grid
          className={styles["grid-item"] + " " + styles["camera-preview"]}
          item
          xs={12}
          xl={6}
        >
          <h1 className={styles["angle-name"]}>
            {labelsMap[language][currentAngle.toUpperCase() + "_TEETH"]}
          </h1>
          <div className={styles["camera-content"]}>
          <CameraPreview angle={currentAngle}/>
          </div>
        </Grid>

        <Grid
          className={styles["grid-item"] + " " + styles["video-guide"]}
          item
          xs={12}
          xl={6}
        >
          <img
            src={
              process.env.PUBLIC_URL + "/images/" + currentAngle + "-guide.png"
            }
          />
        </Grid>
      </Grid>
    </>
  );
}
