import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
// import "./index.css";

import Home from "./containers/Home/Home";
// import Setting from "./containers/Setting/Setting";

// import MainLayout from "./layouts/MainLayout";
import EmptyLayout from "./layouts/EmptyLayout";

import { getTheme } from "./containers/Setting/settingsReducer";
import Login from "./containers/Login/Login";
import partnerLogin from "./containers/partnerLogin/Login";
import Scan from "./containers/Scan/Scan";
import Report from "./containers/Report/Report";
import Appointment from "containers/Appointment/Appointment";
import Download from "containers/Download/Download";
import { useIdleTimer } from "react-idle-timer";
import { resetState } from "store/rootReducer";
import { makeStyles } from "@material-ui/core/styles";
import { closeKeyboard } from "containers/keyboard/action";
import AutoHeightAdjust from "containers/AutoHeightAdjust/autoHeightAdjust";
import ScanAngles from "containers/ScanAngles/scanAngles";
import KioskSelection from "containers/KioskSelection/KioskSelection";
import { getKioskId } from "store/rootReducer";
import { getPartnerToken } from "store/rootReducer";
import { setAutoHeightAdjust } from "store/rootReducer";
import { setLocation } from "store/rootReducer";
import { setLanguageList } from "store/rootReducer";
import { setBypassOTP } from "store/rootReducer";
import { getKioskConfig } from "services/rootService";
import { setBypassLogin } from "store/rootReducer";
import { ControlDialog } from "containers/ControlDialog/ControlDialog";
import WifiConfig from "containers/wifiConfig/wifiConfig";
const SESSION_IDEL_MINUTES = 150;
console.log("Init");
// const SESSION_IDEL_MINUTES = 1;

const NotFound = () => {
  return <div>NotFound</div>;
};

const DashboardRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleOnIdle = (event) => {
    dispatch(resetState());
    dispatch(closeKeyboard()); // dispatch action to close keyboard
    history.push("/");
    console.log("report");
  };

  const { getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * SESSION_IDEL_MINUTES,
    onIdle: handleOnIdle,
    debounce: 500,
  });
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <EmptyLayout>
          <Component {...matchProps} />
        </EmptyLayout>
      )}
    />
  );
};

const EmptyRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <EmptyLayout>
          <Component {...matchProps} />
        </EmptyLayout>
      )}
    />
  );
};
const useStyles = makeStyles({
  app: {
    height: "100vh",
  },
});

export default function App() {
  const classes = useStyles();
  const theTheme = useSelector(getTheme);

  const dispatch = useDispatch();

  const kioskId = useSelector(getKioskId);
  const partnerToken = useSelector(getPartnerToken);
  useEffect(() => {
    console.log(partnerToken);
    if (kioskId && partnerToken) {
      getKioskConfig(kioskId, partnerToken)
        .then((data) => {
          dispatch(setAutoHeightAdjust(data.data.autoHeightAdjust));
          dispatch(setLocation(data.data.code));
          dispatch(
            setLanguageList(
              data.data.languages.map((language) => {
                return language;
              })
            )
          );
          dispatch(setBypassOTP(data.data.bypassOtp));
          dispatch(setBypassLogin(data.data.bypasslogin));
          dispatch(set(data.data.bypassLogin));
        })
        .catch((error) => {
          // Handle the error as appropriate
        });
    }
  }, [kioskId, partnerToken, dispatch]);
  return (
    <MuiThemeProvider theme={createMuiTheme(theTheme)}>
      <CssBaseline />
      <div className={classes.app}>
        <Router>
          <ControlDialog />
          <Switch>
            {/* <DashboardRoute path="/dashboard" component={Home} /> */}
            {/* <DashboardRoute path="/setting" component={Setting} /> */}
            <DashboardRoute exact path="/download" component={Download} />
            <DashboardRoute exact path="/appointment" component={Appointment} />
            <DashboardRoute exact path="/report" component={Report} />
            <DashboardRoute exact path="/scan" component={Scan} />
            <DashboardRoute exact path="/login" component={Login} />
            <DashboardRoute exact path="/wifiConfig" component={WifiConfig} />
            <DashboardRoute exact path="/" component={partnerLogin} />
            <DashboardRoute
              exact
              path="/kioskSelection"
              component={KioskSelection}
            />
            <DashboardRoute exact path="/home" component={Home} />
            <DashboardRoute
              exact
              path="/autoHeight"
              component={AutoHeightAdjust}
            />
            <DashboardRoute exact path="/scanAngles" component={ScanAngles} />
            <EmptyRoute component={NotFound} />
          </Switch>
        </Router>
      </div>
    </MuiThemeProvider>
  );
}
