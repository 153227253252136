import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import * as React from "react";

// console.log({ All });
// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

export default function CustomSnackBar({
  open = false,
  severity = "success",
  message = "",
  setSnackBarData,
}) {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarData({
      open: false,
      severity: "success",
      message: "",
    });
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
