import zIndex from "@material-ui/core/styles/zIndex";
import React from "react";
export default function CameraPreview(props) {
  const videoFrameData = `${process.env.REACT_APP_PI_HOST_URL}:${process.env.REACT_APP_PI_VIDEO_PORT}/stream.mjpg`;
  return (
    <div
    style={{position:"relative",
    height:"700px",
    margin:"auto",
  }}
    >
      <img
      style={{
        height: "650px",
        width: "90%",
        position:"absolute",
        top:"0px",
        left:"5%",
      }}
      alt="Received Video Frame"
      src={videoFrameData}
    />
   {
    props.angle && ( <img
      src={process.env.PUBLIC_URL + "/images/"+props.angle+"-overlay.png"}
      style={{
        zIndex:"1",
        position:"absolute",
        height: "650px",
        width: "90%",
        top:"0px",
        left:"5%",
      }}
    />)
   }
    </div>
  );
}