import { io } from "socket.io-client";
import { EventEmitter } from "events";

class SocketService extends EventEmitter {
  constructor() {
    super();

    this.socket = io(
      `${process.env.REACT_APP_PI_HOST_URL}:${process.env.REACT_APP_PI_SOCKET_PORT}`
    );
    this.initializeSocketEvents();
  }

  initializeSocketEvents = () => {
    this.socket.on("connect", this.handleConnect);
    this.socket.on("disconnect", this.handleDisconnect);
    this.socket.on("video_stream", this.handleVideoStream);
    this.socket.on("adjust_height_result", this.handleAdjustHeightResult);
    this.socket.on("current_angle", this.handleCurrentAngle);
    this.socket.on("scan_complete", this.handleScanComplete);
    this.socket.on("detecting", this.handleDetectingHold);
    this.socket.on("detected", this.handleDetectedCapture);
    this.socket.on("image_data", this.handleImageUpload);
    this.socket.on("network_results", this.handleNetworkResults);
  };

  handleConnect = () => {
    console.log("Connected to server");
    this.emit("connectionChange", true);
  };
  handleDetectedCapture = () => {
    console.log("handleDetectedCapture");
    this.emit("capture", true);
  };

  handleImageUpload = async (data) => {
    console.log("handleImageUpload");
    this.emit("imageUpload", data);
  };

  handleDetectingHold = () => {
    console.log("handleDetectingHold");
    this.emit("hold", true);
  };
  handleDisconnect = () => {
    console.log("Disconnected from server");
    this.emit("connectionChange", false);
  };

  handleVideoStream = (encodedFrame) => {
    const frameData = `data:image/jpeg;base64,${encodedFrame}`;
    this.emit("videoFrameChange", frameData);
  };

  handleAdjustHeightResult = (result) => {
    console.log("Received adjust height result:", result);
    this.emit("heightAdjustment", result);
  };

  handleCurrentAngle = (angle) => {
    console.log("Received current angle:", angle);
    this.emit("currentAngle", angle);
  };

  handleScanComplete = (status) => {
    console.log("Received scan complete status:", status);
    this.emit("scanComplete", status);
  };

  handleNetworkResults = (result) => {
    console.log("Network  results ", result);
  };

  initiate = (name, number, token, autoHeightAdjustment, location, testId) => {
    console.log("Sending initiate event with data:", {
      name,
      number,
      token,
      autoHeightAdjustment,
      location,
      testId,
    });
    this.socket.emit("initiate_video", {
      name,
      number,
      token,
      autoHeightAdjustment,
      location,
      testId,
    });
  };

  adjustHeight = () => {
    console.log("Sending adjust height request.");
    this.socket.emit("adjust_height");
  };

  stepUp = () => {
    console.log("Sending step up request.");
    this.socket.emit("step_up");
  };

  stepDown = () => {
    console.log("Sending step down request.");
    this.socket.emit("step_down");
  };

  detectJaw = (count = 3) => {
    console.log("Sending detect jaw request.");
    this.socket.emit("detect_jaw", {
      count,
    });
  };

  reset = () => {
    console.log("Sending reset request.");
    this.socket.emit("reset");
  };

  shutdown = () => {
    console.log("Shutdown");
    this.socket.emit("shutdown");
  };

  reboot = () => {
    console.log("reboot");
    this.socket.emit("reboot");
  };

  setServo = (angle) => {
    console.log("Setting Servo Angle", angle);
    this.socket.emit("set_servo", { angle });
  };

  ledOn = () => {
    console.log("led on");
    this.socket.emit("led_on");
  };

  ledOff = () => {
    console.log("led off");
    this.socket.emit("led_off");
  };

  motorUp = () => {
    console.log("moveUp");
    this.socket.emit("motor_up");
  };

  motorDown = () => {
    console.log("moveDown");
    this.socket.emit("motor_down");
  };

  motorStop = () => {
    console.log("stopMovement");
    this.socket.emit("motor_stop");
  };

  getWifiNetworks = () => {
    console.log("get wifi networks");
    this.socket.emit("list_networks");
  };

  connectWifi = (ssid, password) => {
    console.log("connect wifi network");
    this.socket.emit("connect_network", {
      ssid,
      password,
    });
  };

  connectWifiAsync = async (ssid, password) => {
    console.log("connect wifi network", ssid, password);

    this.socket.emit("connect_network", {
      ssid,
      password,
    });

    return new Promise((resolve, reject) => {
      this.socket.once("network_status", (data) => {
        resolve(data);
      });

      setTimeout(() => {
        reject(new Error("Timeout: No response from server"));
      }, 10000);
    });
  };

  getWifiNetworksAsync = async () => {
    console.log("get wifi networks");

    this.socket.emit("list_networks");

    return new Promise((resolve, reject) => {
      this.socket.once("network_results", (data) => {
        resolve(data);
      });

      setTimeout(() => {
        reject(new Error("Timeout: No response from server"));
      }, 10000);
    });
  };

  getCurrentWifiDetailsAsync = async () => {
    this.socket.emit("get_ssid");

    return new Promise((resolve, reject) => {
      this.socket.once("ssid_results", (data) => {
        resolve(data);
      });

      setTimeout(() => {
        reject(new Error("Timeout: No response from server"));
      }, 10000);
    });
  };
}

export default new SocketService();
