import {
  OPEN_KEYBOARD,
  CLOSE_KEYBOARD,
  UPDATE_INPUT_VALUE,
  BUTTON_PRESS,
} from "./action";

const initialState = {
  isOpen: false,
  currentInput: null,
  value: "",
};

const maxLengths = {
  name: 50,
  otp: 6,
  mobile: 10,
  token: 15,
};
function keyboardReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_KEYBOARD:
      return {
        ...state,
        isOpen: true,
        currentInput: action.payload.currentInput,
        value: document.getElementById(action.payload.currentInput)?.value,
      };
    case CLOSE_KEYBOARD:
      return {
        ...state,
        currentInput: null,
        value: null,
        isOpen: false,
      };
    case BUTTON_PRESS:
      const { button } = action.payload;
      const inputElement = document.getElementById(state.currentInput);

      if (!inputElement) return state; // exit if the element doesn't exist

      const startPosition = inputElement.selectionStart;
      const endPosition = inputElement.selectionEnd;

      let inputValue = inputElement.value;

      if (button === "\u232B") {
        inputValue =
          inputValue.slice(0, startPosition - 1) +
          inputValue.slice(endPosition);
        inputElement.selectionStart = inputElement.selectionEnd =
          startPosition - 1;
      } else if (button === "{space}") {
        inputValue =
          inputValue.slice(0, startPosition) +
          " " +
          inputValue.slice(endPosition);
        inputElement.selectionStart = inputElement.selectionEnd =
          startPosition + 1;
      } else if (
        button !== "{shift}" &&
        button !== "{lock}" &&
        button !== "{enter}"
      ) {
        inputValue =
          inputValue.slice(0, startPosition) +
          button +
          inputValue.slice(endPosition);
        inputElement.selectionStart = inputElement.selectionEnd =
          startPosition + button.length;
      }

      return {
        ...state,
        value: maxLengths[state.currentInput]
          ? inputValue.slice(0, maxLengths[state.currentInput])
          : inputValue,
      };

    default:
      return state;
  }
}

export { keyboardReducer, maxLengths };
