import { useEffect, useState } from "react";
import ManualAdjustButtons from "../ManualAdjustArrow/ManualAdjust";
import { Grid } from "@material-ui/core";
import styles from "./autoHeightAdjust.module.css";
import { useSelector } from "react-redux";
import { getLanguage } from "store/rootReducer";
import { labelsMap } from "utils/i18n";
import socketService from "services/socketService";
import { getTestId } from "store/rootReducer";
import { getLocation } from "store/rootReducer";
import { getUserDetails } from "store/rootReducer";
import { getToken } from "store/rootReducer";
import { getAutoHeightAdjust } from "store/rootReducer";
import { useHistory } from "react-router";
import audioGuide from "services/audioGuide";
import CameraPreview from "containers/CameraPreview/cameraPreview";

export default function AutoHeightAdjust() {
  const history = useHistory();

  const testId = useSelector(getTestId);
  const location = useSelector(getLocation);
  const userDetails = useSelector(getUserDetails);
  const language = useSelector(getLanguage);
  const authToken = useSelector(getToken);
  const autoHeightAdjust = useSelector(getAutoHeightAdjust);
  const [showManualheightButtons, setShowManualheightButtons] = useState(false);

  const { START_SCAN, CAMERA_ADJUSTING_TEXT, PLEASE_WAIT } =
    labelsMap[language];
  useEffect(() => {
    socketService.initiate(
      userDetails.name,
      userDetails.mobile,
      authToken,
      autoHeightAdjust,
      location,
      testId
    );
    socketService.adjustHeight();
    socketService.on("heightAdjustment", handleAdjustHeightResult);
    audioGuide.playSound("adjust_camera_height");
  }, []);

  const handleAdjustHeightResult = (res) => {
    setShowManualheightButtons(true);
  };

  const startScan = () => {
    history.push("/scanAngles");
  };
  return (
    <>
      <div className={styles.logo}>
        <img
          src={"./images/" + "logo_scano.png"}
          height="50px"
          alt="Scano Logo"
        ></img>
      </div>
      {showManualheightButtons && (
        <div className={styles["manual-height"]}>
          <ManualAdjustButtons />
        </div>
      )}
      <Grid
        className={styles.grid}
        container
        style={{
          margin: "0px",
        }}
      >
        <Grid
          className={`${styles["grid-item"]} ${styles["adjusting-guide"]}`}
          item
          xs={12}
          xl={6}
        >
          <div
            className={`${styles["adjust-height-container"]}`}
            style={{ display: showManualheightButtons ? "none" : "block" }}
          >
            <div className={styles["text-guide-label"]}>{PLEASE_WAIT}</div>
            <br />
            <div className={styles["text-guide-label"]}>
              {CAMERA_ADJUSTING_TEXT}
            </div>
          </div>

          <div
            className={`${styles["camera-preview"]}`}
            style={{
              display: showManualheightButtons ? "block" : "none",
              width: "100%",
            }}
          >
            <CameraPreview />
          </div>
        </Grid>

        <Grid
          className={`${styles["grid-item"]} ${styles["video-guide"]}`}
          item
          xs={12}
          xl={6}
        >
          <video key={"english" + "-video"} muted loop autoPlay>
            <source
              src={"./videos/" + "height-video-guide" + ".mp4"}
              type="video/mp4"
            />
          </video>
        </Grid>
      </Grid>
      {showManualheightButtons && (
        <div className={styles["btn-container"]}>
          <button className={styles["start-scan-btn"]} onClick={startScan}>
            {START_SCAN}
          </button>
        </div>
      )}
    </>
  );
}
