import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import CustomSnackBar from "../../components/CustomSnackBar";
import {
  partnerLogin,
  signUp,
  submitOtp,
  testInit,
  updateUser,
} from "../../services/rootService";
import {
  changeLanguage,
  getLanguage,
  setTestId,
  setToken,
  setUserDetails,
  getTestMode,
  setTestMode,
  setPartnerToken,
  setLocation,
  getLocation,
  setAutoHeightAdjust,
} from "../../store/rootReducer";
import { labelsMap } from "../../utils/i18n";
import CircularProgress from "@mui/material/CircularProgress";
import ScreenKeyboard from "containers/keyboard/screenKeyboard";
import {
  openKeyboard,
  closeKeyboard,
  updateInputValue,
} from "containers/keyboard/action";
import { maxLengths } from "containers/keyboard/reducer";
export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSessionId, setOtpSessionId] = useState("");
  const [isNameValid, setIsNameValid] = useState(true);
  const [isMobileValid, setIsMobileValid] = useState(true);
  const [isOtpMode, setIsOtpMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSubmitOtp, setIsLoadingSubmitOtp] = useState(true);
  const [isLoadingResendOtp, setIsLoadingResendOtp] = useState(true);
  const [snackBarData, setSnackBarData] = React.useState({
    open: false,
    severity: "success",
    message: "",
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const language = useSelector(getLanguage);
  const testMode = useSelector(getTestMode);
  const {
    LOGIN_HEADING,
    USER_LOGIN_HEADING,
    LOGIN_SUBHEADING,
    LOGIN_INPUT_NAME,
    LOGIN_INPUT_USERNAME,
    LOGIN_INPUT_PASSWORD,
    LOGIN_INPUT_MOBILE,
    LOGIN_INPUT_OTP,
    LOGIN_BUTTON_SEND_OTP,
    LOGIN_USER_BUTTON,
    LOGIN_BUTTON_SUBMIT_OTP,
    LOGIN_BUTTON_RESEND_OTP,
    LOGIN_BUTTON_EDIT_NUMBER,
    LOGIN_MESSAGE_OTP_SENT,
    LOGIN_MESSAGE_INVALID_OTP,
    LOGIN_MESSAGE_OTP_ERROR,
    LOGIN_MESSAGE_INVALID_DATA,
    LOGIN_MESSAGE_OTP_SUBMIT_ERROR,
  } = labelsMap[language];
  const regexMap = {
    name: "^[a-zA-Z\\s]*$",
    mobile: "^(\\d *){10}$",
  };
  const nameValue = useSelector((state) =>
    state.keyboard.isOpen && state.keyboard.currentInput === "username"
      ? state.keyboard.value
      : username
  );
  const passwordValue = useSelector((state) =>
    state.keyboard.isOpen && state.keyboard.currentInput === "password"
      ? state.keyboard.value
      : password
  );

  const location = useSelector(getLocation);

  const keyboardState = useSelector((state) => state.keyboard); // Access the keyboard state from your Redux store

  useEffect(() => {
    // Perform validation whenever keyboardState changes
    if (keyboardState.isOpen) {
      if (keyboardState.currentInput === "username") {
        setIsNameValid(
          validateValue(regexMap["username"], keyboardState.value)
        );
      } else if (keyboardState.currentInput === "password") {
        setIsMobileValid(
          validateValue(regexMap["password"], keyboardState.value)
        );
      }
    }
  }, [keyboardState]);

  useEffect(() => {
    if (localStorage.getItem("partnerToken")) {
      if (localStorage.getItem("kioskId")) {
        history.push("/home");
      } else {
        localStorage.clear();
        history.push("/");
      }
    }
  }, []);

  const onFocus = (event) => {
    dispatch(openKeyboard(event.target.id, event.target.value));
  };
  const onBlur = (event) => {
    // Update the corresponding state variables
    if (event.target.id === "username") {
      setUsername(event.target.value);
    } else if (event.target.id === "password") {
      setPassword(event.target.value);
    }

    dispatch(closeKeyboard());
  };
  const validateValue = (regex, value) => {
    const reg = new RegExp(regex);
    const result = reg.test(value);
    return result;
  };

  const validateForm = () => {
    return !!(name && mobile && isNameValid && isMobileValid);
  };

  const handleNameChange = (event) => {
    const { value } = event.target;
    dispatch(updateInputValue(value));
    setUsername(value);
  };

  const handlePasswordChange = (event) => {
    const { value } = event.target;
    dispatch(updateInputValue(value));
    setPassword(value);
  };

  const handleSubmitClick = async (event) => {
    const result = await partnerLogin(username, password);
    if (result?.data?.data?.token) {
      let token = result.data.data.token;
      console.log("token", token);
      dispatch(setPartnerToken(token));
      history.push("/kioskSelection");
    } else {
      console.error("Token not found in the response");
      // Handle error scenario, maybe notify the user
    }
  };

  return (
    <>
      <div style={{ padding: 10, height: "100%" }}>
        <Grid
          container
          style={{
            justifyContent: "center",
            flexDirection: "reverse",
            height: "100%",
          }}
        >
          <Grid item xs={12} md={6} lg={6} x1={6}>
            <div
              style={{
                height: "5vh",
                display: "flex",
                flexDirection: "row",
                alignItems: "end",
                justifyContent: "end",
              }}
            >
              &nbsp; &nbsp;
              <Select
                labelId="language-label"
                id="language-select"
                value={language}
                label="Language"
                onChange={(event) => {
                  if (event && event.target && event.target.value) {
                    dispatch(changeLanguage(event.target.value));
                  }
                }}
              >
                <MenuItem value={"english"}>English</MenuItem>
                <MenuItem value={"hindi"}>हिंदी</MenuItem>
                <MenuItem value={"tamil"}>Tamil</MenuItem>
              </Select>
            </div>
            <div
              style={{
                height: "80%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <img
                alt="logo"
                style={{ width: "25%", objectFit: "contain" }}
                src={process.env.PUBLIC_URL + "/images/logo_scano.png"}
              />
              <div className="heading1">{USER_LOGIN_HEADING}</div>
              <div className="heading2">{LOGIN_SUBHEADING}</div>
              <div style={{ height: "2em" }}></div>
              <>
                <div style={{ width: "50%" }}>
                  <FormControl fullWidth sx={{ m: 2 }}>
                    <TextField
                      id="username"
                      label={LOGIN_INPUT_USERNAME}
                      variant="outlined"
                      value={nameValue}
                      onChange={handleNameChange}
                      error={!isNameValid}
                      onFocus={onFocus}
                      onBlur={onBlur}
                    />
                  </FormControl>
                </div>
                <div style={{ height: "2em" }}></div>
                <div style={{ width: "50%" }}>
                  <FormControl fullWidth sx={{ m: 2 }}>
                    <TextField
                      id="password"
                      label={LOGIN_INPUT_PASSWORD}
                      variant="outlined"
                      value={passwordValue}
                      onChange={handlePasswordChange}
                      error={!isMobileValid}
                      onFocus={onFocus}
                      onBlur={onBlur}
                    />
                  </FormControl>
                </div>
              </>

              <div style={{ height: "2em" }}></div>
              <div style={{ width: "50%" }}>
                <Button
                  style={{ backgroundColor: "#052679", color: "white" }}
                  variant="contained"
                  fullWidth
                  onClick={handleSubmitClick}
                  disabled={isLoading === false}
                >
                  <span style={{ fontSize: "1.5em" }}>
                    {isLoading ? (
                      LOGIN_USER_BUTTON
                    ) : (
                      <>
                        <CircularProgress color="inherit" />
                      </>
                    )}
                  </span>
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
        <CustomSnackBar setSnackBarData={setSnackBarData} {...snackBarData} />
      </div>
      <ScreenKeyboard />
    </>
  );
}
