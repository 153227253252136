import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  useMediaQuery,
} from "@material-ui/core";
import styles from "./KioskSelection.module.css";
import { getPartnerConfig } from "services/rootService";
import { setKioskId } from "store/rootReducer";
import { useHistory } from "react-router";
import { setClinicId } from "store/rootReducer";
import { getPartnerToken } from "store/rootReducer";
import { Label } from "@material-ui/icons";
export default function KioskSelection() {
  const history = useHistory();
  const [clinic, setClinic] = useState("");
  const [kiosk, setKiosk] = useState("");
  const [clinics, setClinics] = useState([]);
  const [kiosks, setKiosks] = useState([]);
  const partnerAuthToken = useSelector(getPartnerToken);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchConfig() {
      const response = await getPartnerConfig(partnerAuthToken);
      console.log(response.data);
      setClinics(response.data.clinics);
      console.log("Clinics", response.data.clinics);
    }
    fetchConfig();
  }, []);

  useEffect(() => {
    if (clinic) {
      const selectedClinic = clinics.find((c) => c._id === clinic);
      setKiosks(selectedClinic?.kiosk || []);
    }
  }, [clinic, clinics]);

  const handleClinicChange = (event) => {
    setClinic(event.target.value);
  };

  const handleKioskChange = (event) => {
    setKiosk(event.target.value);
  };

  const validateForm = () => {
    return clinic && kiosk;
  };

  const handleSubmitClick = () => {
    // dispatch(yourAction({ clinic, kiosk }));
    dispatch(setKioskId(kiosk));
    dispatch(setClinicId(clinic));
    history.push("/home");
  };

  return (
    <div className={styles["container"]}>
      <Grid className={styles["grid"]} container spacing={3}>
        <Grid className={`${styles["grid-item"]}`} item xs={12} xl={6}>
          <FormControl style={{ margin: "15px", width: "100%" }}>
            <InputLabel id="clinic-label">Select Clinic</InputLabel>
            <Select
              id="clinic-select"
              value={clinic}
              onChange={handleClinicChange}
            >
              {clinics.map((c) => (
                <MenuItem key={c._id} value={c._id}>
                  {c.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{ margin: "15px", width: "100%" }}>
            <InputLabel id="kiosk-label">Select Kiosk</InputLabel>
            <Select
              id="kiosk-select"
              value={kiosk}
              onChange={handleKioskChange}
            >
              {kiosks.map((k) => (
                <MenuItem key={k._id} value={k._id}>
                  {k.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            onClick={handleSubmitClick}
            disabled={!validateForm()}
            style={{
              backgroundColor: "#052679",
              color: "white",
              margin: "15px",
              width: "100%",
            }}
          >
            Submit
          </Button>
        </Grid>

        <Grid className={`${styles["grid-item"]}`} item xs={12} xl={6}>
          <video
            key={"english" + "-video"}
            muted
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
            loop
            autoPlay
          >
            <source src={"./videos/" + "english" + ".mp4"} type="video/mp4" />
          </video>
        </Grid>
      </Grid>
    </div>
  );
}
