import Axios from "axios";

// const API_URL = "https://api.dentaldost.co";
const API_URL = process.env.REACT_APP_API_HOST_URL;

const getOtp = async (mobile) => {
  return Axios.post(`${API_URL}/auth/sendOTP`, {
    mobile,
    countryCode: "+91",
  });
};

const bypassOtp = async (name, mobile, kioskId) => {
  return Axios.post(`${API_URL}/auth/bypassOtp`, {
    mobile,
    name: name,
    countryCode: "+91",
    kioskId: kioskId,
    isKiosk: true,
  });
};

const partnerLogin = (username, password) => {
  return Axios.post(`${API_URL}/auth/doctor/login`, {
    username,
    password,
  });
};

const submitOtp = async (mobile, otp, sessionId) => {
  return Axios.post(`${API_URL}/auth/verifyOTP`, {
    mobile,
    countryCode: "+91",
    otp,
    token: sessionId,
  });
};

const testInit = async (authToken, kioskId) => {
  return Axios.post(
    `${API_URL}/user/test/initTest`,
    {
      kioskId: kioskId,
    },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

const checkVideoStatus = async (authToken, testId) => {
  return Axios.post(
    `${API_URL}/user/test/checkVideoStatus`,
    { test_id: testId, imageCount: 3, isKiosk: true },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

const sendReportOnWhatsapp = async (authToken, testId) => {
  try {
    return Axios.post(
      `${API_URL}/agent/patient/sendKioskReportOnWhatsapp`,
      { testId: testId },
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );
  } catch (error) {
    console.log("Error while sending report on whatsapp", error);
    return null;
  }
};

const checkStatus = async (authToken, testId) => {
  return Axios.post(
    `${API_URL}/user/test/checkStatus`,
    { test_id: testId, imageCount: 3, isKiosk: true },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

const bypassLogin = async (partnerAuthToken) => {
  return Axios.get(`${API_URL}/auth/bypassLogin`, {
    headers: { Authorization: `Bearer ${partnerAuthToken}` },
  });
};

const getKioskConfig = async (kioskId, partnerAuthToken) => {
  console.log("getKioskConfig", kioskId, partnerAuthToken);
  return Axios.get(`${API_URL}/kiosk/getKioskConfig`, {
    params: { kioskId: kioskId },
    headers: { Authorization: `Bearer ${partnerAuthToken}` },
  });
};

const getPartnerConfig = async (partnerAuthToken) => {
  console.log("getKioskConfig", partnerAuthToken);
  return Axios.get(`${API_URL}/kiosk/getPartnerConfig`, {
    headers: { Authorization: `Bearer ${partnerAuthToken}` },
  });
};
const getTestReport = async (authToken, testId) => {
  return Axios.post(
    `${API_URL}/user/test/getDiseaseData`,
    {
      test_id: testId,
    },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

const addTaskwithDiseases = async (authToken, testId, angle, imageData) => {
  const formData = new FormData();
  formData.append("input_image", imageData);
  formData.append("test_id", testId);
  formData.append("angle", angle);
  return Axios.post(
    `${API_URL}/user/test/addTaskKioskWithDiseases`,
    formData,

    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
};

const updateUser = async (authToken, name, kioskId) => {
  return Axios.post(
    `${API_URL}/user/updateProfile`,
    { name, kioskId: kioskId },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

const signUp = async (name, mobile, location) => {
  return Axios.post(
    `${API_URL}/auth/signup`,
    {
      name,
      mobile,
      countryCode: "+91",
      isKiosk: true,
      kioskLocation: location,
    }
    // {
    //   headers: { Authorization: `Bearer ${authToken}` },
    // }
  );
};

const bookAppointment = async (authToken) => {
  return Axios.post(
    `${API_URL}/user/appointmentBook`,
    {},
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};
const testSubmitedKiosk = async (authToken) => {
  return Axios.get(`${API_URL}/user/testSubmitKiosk`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

const getReportFileUrl = async (data, authToken) => {
  // return Axios.post(
  //   `${DASHBOARD_API_URL}/agent/patient/sendKioskReportOnEmail`,
  //   data,
  //   {
  //     headers: { Authorization: `Bearer ${authToken}` },
  //   }
  // );
};

export {
  partnerLogin,
  getOtp,
  submitOtp,
  testInit,
  checkStatus,
  getTestReport,
  updateUser,
  signUp,
  bookAppointment,
  checkVideoStatus,
  testSubmitedKiosk,
  getReportFileUrl,
  getPartnerConfig,
  getKioskConfig,
  API_URL,
  bypassOtp,
  bypassLogin,
  sendReportOnWhatsapp,
  addTaskwithDiseases,
};
