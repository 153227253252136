import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Grid,
  Tabs,
  Tab,
  Box,
  Typography,
  CircularProgress,
  Stepper,
  Step,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import { ArrowBackOutlined, ArrowForwardOutlined } from "@material-ui/icons";
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import TabList from "@material-ui/lab/TabList";
import { useSelector } from "react-redux";
import {
  getTestId,
  getToken,
  getLanguage,
  getUserDetails,
  getBypassLogin,
  getBypassOtp,
  getPatientNumber,
} from "../../store/rootReducer";
import {
  checkStatus,
  checkVideoStatus,
  getTestReport,
  bookAppointment,
  getReportFileUrl,
  sendReportOnWhatsapp,
} from "../../services/rootService";
import { labelsMap } from "../../utils/i18n";
import { useHistory } from "react-router";
import "./report.css";
import moment from "moment";

import VideoPlayer from "./video";
import DiseaseInfo from "./video";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

export default function Report() {
  const IMAGE_URL = "https://ai.dentaldost.co";
  // const testId = "64f8c26b7cc16a002e5c312d"; //useSelector(getTestId);
  const testId = useSelector(getTestId);

  // const authToken =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InNodWJoYW1AZGVudGFsZG9zdC5jb20iLCJpZCI6IjY0ZTg1OWMyM2JjZWNhYmUyZDg3MWZlZCIsImlhdCI6MTY5NDExNDYxNn0.or67nvGWqNjANqU8UDNhgbN1OaPMp1AQlgJeH1NiT1k";

  const authToken = useSelector(getToken);
  const [loadingReportData, setLoadingReportData] = useState(true);
  const [activeStep, setActiveStep] = React.useState(0);
  const [imageUrl, setImageUrl] = useState("");
  const [reportData, setReportData] = useState();
  const [diseasesList, setDiseasesList] = useState([]);
  const language = useSelector(getLanguage);
  const { name, mobile } = useSelector(getUserDetails);
  // const name = "Kalhar"
  // const mobile = "9978784862"
  const [selectedDiseaseTypes, setSelectedDiseaseTypes] = useState([]);
  const [diseaseInformation, setDiseaseInformation] = useState([]);

  // const [testMode] = useState(getTestMode);
  const bypassLogin = useSelector(getBypassLogin);
  const patientNumber = useSelector(getPatientNumber);
  const {
    REPORT_HEADING,
    REPORT_SUB_SECTION_HEADING,
    REPORT_SUB_SECTION_VIDEO_HEADING,
    ANGLE_LIST,
    BOOK_APPOINTMENT_BUTTON,
    DONWLOAD_REPORT_BUTTON,
    NO_DETECTIONS_IN_ANGLE,
    KNOW_MORE,
    EXIT_REPORT_BUTTON,
  } = labelsMap[language];

  const getTreatmentsColor = (disease) => {
    if (labelsMap.diseasesMap[disease.toLowerCase()]) {
      return labelsMap.diseasesMap[disease.toLowerCase()].color;
    } else {
      return "#ffffff";
    }
  };

  const canvasRef = useRef();

  const getTreatments = (disease) => {
    // console.log({ disease: disease.toLowerCase() });
    // if (
    //   labelsMap.diseasesMap[disease.toLowerCase()] &&
    //   labelsMap.diseasesMap[disease.toLowerCase()][language]
    // ) {
    return labelsMap.diseasesMap[disease.toLowerCase()][language];
    // }
  };

  useEffect(() => {
    if (!reportData) {
      return;
    }
    let angle;
    switch (activeStep) {
      case 0: // Upper
        angle = "front";
        break;
      case 1: // Lower
        angle = "upper";
        break;
      case 2: // Front
        angle = "lower";
        break;
      default:
        angle = "front";
        break;
    }
    let detection = reportData.test.find(
      (d) => d.angle.toLowerCase() === angle
    );
    console.log(reportData, angle, detection);
    if (detection) {
      const uniqueDiseaseTypes = [
        ...new Set(
          detection.diseases.map((disease) => disease.name.toLowerCase())
        ),
      ];
      setSelectedDiseaseTypes(uniqueDiseaseTypes);
      setDiseasesList(detection.diseases);
      console.log("list", detection.diseases);
      setDiseaseInformation(detection?.newDiseaseData);
      const anglesList = ["front", "upper", "lower"];
      const path = reportData.test.find(
        (data) => data.angle === anglesList[activeStep]
      );
      if (path && path.base_file_url) {
        setImageUrl(IMAGE_URL + path.base_file_url);
      }
      // setSelectedDiseaseTypes([]);
    } else {
      console.error(`Detection with angle "${angle}" not found`);
    }
  }, [activeStep, reportData]);
  useEffect(() => {
    if (!imageUrl) {
      return;
    }

    let FIXED_HEIGHT = 300; // You can set this value to whatever height you want
    const screenHeight = window.innerHeight;
    if (screenHeight < 1000) {
      FIXED_HEIGHT = 200;
    }
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const image = new Image();
    image.src = imageUrl;

    image.onload = () => {
      // Scale based on the fixed height
      const scale = FIXED_HEIGHT / image.height;

      // Set canvas dimensions
      canvas.height = FIXED_HEIGHT;
      canvas.width = image.width * scale;

      // Draw the image on canvas
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, 0, 0, canvas.width, canvas.height);

      // Annotations
      drawAnnotations(context, 0, 0, scale);
    };
  }, [imageUrl, selectedDiseaseTypes, diseasesList]);

  const drawRectangle = (ctx, coordinates, color, offsetX, offsetY, scale) => {
    const [x1, y1, x2, y2] = coordinates;
    ctx.globalAlpha = 0.7;
    ctx.fillStyle = color;
    ctx.fillRect(
      offsetX + x1 * scale,
      offsetY + y1 * scale,
      (x2 - x1) * scale,
      (y2 - y1) * scale
    );
  };

  const drawAnnotations = (context, offsetX, offsetY, scale) => {
    const diseaseMap = fillInDiseaseInformation(diseasesList);
    selectedDiseaseTypes.forEach((diseaseType) => {
      const diseases = diseaseMap[diseaseType];
      const color = getTreatmentsColor(diseaseType);
      if (diseases) {
        diseases.forEach((disease) => {
          const coordinates = disease.coordinates;
          coordinates.forEach((coord) =>
            drawRectangle(context, coord, color, offsetX, offsetY, scale)
          );
        });
      }
    });
  };

  const history = useHistory();
  const handleStep = (step) => {
    console.log("step", step);
    setActiveStep(step);
  };

  const fillInDiseaseInformation = (diseases) => {
    const diseaseMap = {};

    diseases.forEach((disease) => {
      const diseaseType = disease.name.toLowerCase();
      if (Object.keys(labelsMap.diseasesMap).includes(diseaseType)) {
        if (!diseaseMap[diseaseType]) {
          diseaseMap[diseaseType] = [];
        }
        diseaseMap[diseaseType].push(disease);
      }
    });
    return diseaseMap;
  };

  const waitTillTestCompletes = async () => {
    try {
      await checkStatus(authToken, testId);
      const result = await getTestReport(authToken, testId);
      if (result && result.data) {
        setReportData(result.data);
      }
      setLoadingReportData(false);
      return true;
    } catch (error) {
      await new Promise((resolve) => setTimeout(resolve, 5000));
      waitTillTestCompletes();
    }
  };

  const getImageByAngleIndex = () => {
    const anglesList = ["front", "upper", "lower"];
    if (reportData && reportData.test) {
      const path = reportData.test.find(
        (data) => data.angle.toLowerCase() === anglesList[activeStep]
      );
      if (path && path.base_file_url) {
        setImageUrl(IMAGE_URL + path.base_file_url);
      }
    }
  };

  const handleClickBookAppointment = async () => {
    try {
      const result = await bookAppointment(authToken);
      history.push("/appointment");
    } catch (error) {
      console.log(error);
      history.push("/appointment");
    }
  };

  useEffect(() => {
    getImageByAngleIndex();
  }, [reportData, activeStep]);

  useEffect(() => {
    waitTillTestCompletes();
  }, []);

  useEffect(() => {
    if (!loadingReportData) {
      sendReportOnEmail(authToken);
    }
  }, [loadingReportData, authToken]);

  const sendReportOnEmail = (authToken) => {
    try {
      let testData = {
        reportType: "detected",
        testId: testId,
        reportNo: 1,
      };
      const { data } = getReportFileUrl(testData, authToken);
    } catch (error) {
      console.log("something went wrong for the below record");
    }
  };
  let diseasesKeys = Object.keys(fillInDiseaseInformation(diseasesList));

  const handleDiseaseTypeChange = (event) => {
    const diseaseName = event.target.name;
    if (selectedDiseaseTypes.includes(diseaseName)) {
      setSelectedDiseaseTypes(
        selectedDiseaseTypes.filter((item) => item !== diseaseName)
      );
    } else {
      setSelectedDiseaseTypes([...selectedDiseaseTypes, diseaseName]);
    }
  };

  const handleDiseaseClick = (disease) => {
    if (selectedDiseaseTypes.includes(disease)) {
      setSelectedDiseaseTypes(
        selectedDiseaseTypes.filter((item) => item !== disease)
      );
    } else {
      setSelectedDiseaseTypes([...selectedDiseaseTypes, disease]);
    }
  };

  const isColorDark = (color) => {
    const colorValue = color.replace("#", "");
    const red = parseInt(colorValue.substr(0, 2), 16);
    const green = parseInt(colorValue.substr(2, 2), 16);
    const blue = parseInt(colorValue.substr(4, 2), 16);
    const luminance = (0.299 * red + 0.587 * green + 0.114 * blue) / 255;

    return luminance < 0.5;
  };

  return (
    <div
      style={{
        height: "100vh",
        overflowY: "scroll",
      }}
    >
      {loadingReportData ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100%",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Grid
          container
          spacing={0}
          style={{
            height: "100%",
          }}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            x1={6}
            style={{
              width: "100%",
              display: "flex",
              paddingLeft: "30px",
            }}
            className="responsive-height"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  paddingTop: "1em",
                  width: "100%",
                }}
              >
                <div style={{ height: "2em" }}></div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    className="heading1"
                    style={{ textAlign: "left", fontSize: "2em" }}
                  >
                    {REPORT_HEADING}
                  </div>
                  <div
                    className="heading1"
                    style={{ textAlign: "right", fontSize: "1.5em" }}
                  >
                    <div>
                      <Button
                        style={{ backgroundColor: "#052679", color: "white" }}
                        fullWidth
                        variant="contained"
                        onClick={() => {
                          if (!bypassLogin) {
                            sendReportOnWhatsapp(authToken, testId);
                          }
                          history.push("/home");
                        }}
                      >
                        <span style={{ fontSize: "1.5em" }}>
                          {EXIT_REPORT_BUTTON}
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
                <div style={{ height: "0.5em" }}></div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt="logo"
                      style={{ width: "10%", objectFit: "contain" }}
                      src={process.env.PUBLIC_URL + "/images/user_icon.png"}
                    />
                    &nbsp;{" "}
                    <span style={{ fontSize: "1.3em" }}>
                      {bypassLogin ? patientNumber : name}
                    </span>
                  </div>
                  {!bypassLogin && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <img
                        alt="logo"
                        style={{ width: "10%", objectFit: "contain" }}
                        src={process.env.PUBLIC_URL + "/images/mobile_icon.png"}
                      />
                      &nbsp; <span style={{ fontSize: "1.3em" }}>{mobile}</span>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt="time logo"
                      style={{ width: "10%", objectFit: "contain" }}
                      src={process.env.PUBLIC_URL + "/images/icons8-time.svg"}
                    />
                    &nbsp;{" "}
                    <span style={{ fontSize: "1.3em" }}>
                      {moment(reportData.testDate).format(
                        "MMMM DD, YYYY hh:mm A"
                      )}
                    </span>
                  </div>
                </div>
                <div style={{ height: "0.5em" }}></div>
                <div className="stepper-container">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#EEF6FF",
                      width: "100%",

                      padding: "10px",
                    }}
                  >
                    {ANGLE_LIST.map((label, index) => (
                      <div key={label} style={{ margin: "0 10px" }}>
                        <button
                          className="buttonClass stepper-button-landscape"
                          onClick={() => {
                            handleStep(index);
                            console.log("Stepp", index, handleStep);
                          }}
                          style={{
                            backgroundColor:
                              activeStep === index
                                ? "rgb(5, 38, 121)"
                                : "inherit",
                            color:
                              activeStep === index
                                ? "white"
                                : "rgb(5, 38, 121)",
                            borderRadius: "14px",
                            fontWeight: "bolder",
                            fontSize: "20px",
                            padding: "20px",
                            paddingTop: "15px",
                            paddingBottom: "15px",
                            border: "none",
                          }}
                        >
                          {label}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    textAlign: "center",
                    width: "100%",

                    // height: "100%",
                  }}
                >
                  <div
                    style={{
                      width: "4em",
                      height: "4em",
                      background: "white",
                      borderRadius: "50%",
                      position: "absolute",
                      top: "45%",
                      left: "10%",
                      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)", // Added a basic box-shadow
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        let nextStep = activeStep - 1;
                        if (nextStep < 0) {
                          nextStep = 2;
                        }
                        setActiveStep(nextStep);
                      }}
                      style={{
                        padding: "0.1em",
                        color: "#052679",
                      }}
                    >
                      <ChevronLeftIcon style={{ fontSize: 50 }} />{" "}
                      {/* Replaced ChevronRightIcon with ChevronLeftIcon */}
                    </IconButton>
                  </div>

                  <div
                    style={{
                      height: "100%",
                      borderRadius: "20px",
                      overflow: "hidden",
                      width: "fit-content",
                      margin: "auto",
                    }}
                  >
                    <canvas
                      ref={canvasRef}
                      className="image image-contain"
                      style={{ objectFit: "contain" }}
                      key={imageUrl}
                    ></canvas>
                  </div>

                  <div
                    style={{
                      width: "4em",
                      height: "4em",
                      background: "white",
                      borderRadius: "50%",
                      position: "absolute",
                      top: "45%",
                      right: "10%",
                      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)", // Added box-shadow to remove border
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        let nextStep = activeStep + 1;
                        if (nextStep > 2) {
                          nextStep = 0;
                        }
                        setActiveStep(nextStep);
                      }}
                      style={{
                        padding: "0.1em",
                        color: "#052679",
                      }}
                    >
                      <ChevronRightIcon style={{ fontSize: 50 }} />{" "}
                      {/* Replaced ArrowForwardOutlined with ChevronRightIcon */}
                    </IconButton>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%", marginTop: "1em" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignContent: "space-around",
                    height: "100%",
                  }}
                >
                  {diseasesKeys.map((item, index) => {
                    const isSelected = selectedDiseaseTypes.includes(item);
                    const buttonColor = getTreatmentsColor(item);

                    return (
                      <Button
                        style={{
                          backgroundColor: isSelected
                            ? buttonColor
                            : "transparent",
                          color: isSelected
                            ? isColorDark(buttonColor)
                              ? "white"
                              : "black"
                            : "black",
                          border: `1px solid ${buttonColor}`,
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                        className="button-disease-select"
                        variant="contained"
                        onClick={() => {
                          handleDiseaseClick(item);
                        }}
                      >
                        <span style={{ fontSize: "1.5em" }}>
                          {getTreatments(item) && getTreatments(item).name
                            ? getTreatments(item).name
                            : ""}
                        </span>
                      </Button>
                    );
                  })}
                </div>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            style={{ maxHeight: "720px" }}
            className="responsive-height"
          >
            <div
              style={{
                padding: "4vh",
                height: "100%",
                // overflow: "scroll",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "start",
                  backgroundColor: "#F2F2F2",
                  overflowY: "auto",
                  overflowX: "hidden",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    textAlign: "right",
                    padding: "1em",
                  }}
                >
                  {/* <img
                    alt="logo"
                    style={{ width: "25%", objectFit: "contain" }}
                    src={process.env.PUBLIC_URL + "/images/logo_scano.png"}
                  /> */}
                </div>

                <div
                  className="heading-scan"
                  style={{ textAlign: "left", width: "90%" }}
                >
                  {REPORT_SUB_SECTION_HEADING}
                </div>
                <div className="report-disease-list-container">
                  {diseasesKeys.length > 0 ? (
                    diseasesKeys.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          textAlign: "left",
                          paddingTop: "1em",
                          paddingLeft: "2em",
                          paddingRight: "2em",
                          width: "100%",
                        }}
                        onClick={() => handleDiseaseClick(item)}
                      >
                        <div
                          className="treatment-text"
                          style={{
                            textAlign: "left",
                            pointerEvents: "none",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "start",
                              alignItems: "center",
                              backgroundColor: "#F2F2F2",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span>
                                <strong>
                                  {getTreatments(item) &&
                                  getTreatments(item).name
                                    ? getTreatments(item).name
                                    : ""}
                                </strong>
                              </span>
                              <span style={{ fontSize: "0.8em" }}>
                                {getTreatments(item) &&
                                getTreatments(item).description
                                  ? getTreatments(item).description
                                  : ""}
                              </span>
                            </div>
                          </div>
                          <div style={{ height: "1em" }}></div>
                          <div
                            style={{
                              // paddingLeft: "1em",
                              fontSize: "0.8em",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <span>
                                {getTreatments(item) &&
                                getTreatments(item).treatment
                                  ? getTreatments(item).treatment
                                  : ""}
                              </span>
                              {/* <span>
                                {getTreatments(item) &&
                                getTreatments(item).price
                                  ? getTreatments(item).price
                                  : ""}
                              </span> */}
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>
                    ))
                  ) : (
                    <div style={{ textAlign: "center", paddingTop: "2em" }}>
                      <p className="no-detections">{NO_DETECTIONS_IN_ANGLE}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Grid>
          <div
            style={{
              width: "100%",
              overflow: "auto",
              padding: "30px",
              paddingBottom: "0px",
            }}
          >
            <div
              style={
                {
                  // overflow: "scroll",
                }
              }
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "start",
                  backgroundColor: "#FBFBFB",
                  width: "100%",
                }}
              >
                <div
                  className="heading-scan"
                  style={{ textAlign: "left", width: "100%", marginLeft: 0 }}
                >
                  {REPORT_SUB_SECTION_VIDEO_HEADING}
                </div>
              </div>
              <div
                style={{
                  margin: "0px",
                }}
                className="heading-scan"
              >
                {KNOW_MORE}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  overflowX: "auto",
                  overflowY: "hidden",
                }}
              >
                <DiseaseInfo newDiseaseData={diseaseInformation}></DiseaseInfo>
              </div>
            </div>
          </div>
        </Grid>
      )}
    </div>
  );
}
