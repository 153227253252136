export const labelsMap = {
  kannada: {
    HOME_HEADING: "ನಿಮ್ಮ ಹಲ್ಲುಗಳನ್ನು ರಕ್ಷಿಸಲು ಸರಳ ಮಾರ್ಗ",
    HOME_SUBHEADING: "ಭಾಷೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    LOGIN_HEADING: "ನಮಸ್ತೆ",
    LOGIN_SUBHEADING: "ಆರೋಗ್ಯಕರ ಬಾಯಿಯ ಕಡೆಗೆ ನಿಮ್ಮ ಪ್ರಯಾಣವನ್ನು ಪ್ರಾರಂಭಿಸೋಣ",
    LOGIN_INPUT_NAME: "ಹೆಸರು",
    LOGIN_INPUT_MOBILE: "ಮೊಬೈಲ್ ನಂಬರ",
    LOGIN_INPUT_OTP: "OTP",
    LOGIN_BUTTON_SEND_OTP: "ಕಳುಹಿಸು OTP",
    LOGIN_USER_BUTTON: "Login",
    LOGIN_INPUT_PASSWORD: "Password",
    LOGIN_INPUT_USERNAME: "Username",
    LOGIN_BUTTON_SUBMIT_OTP: "ಸಲ್ಲಿಸು OTP",
    LOGIN_BUTTON_RESEND_OTP: "OTP ಸಿಗಲಿಲ್ಲವೇ? ಮರುಕಳುಹಿಸಿ",
    LOGIN_BUTTON_EDIT_NUMBER: "ಸಂಖ್ಯೆ ಸಂಪಾದಿಸಿ",
    LOGIN_MESSAGE_OTP_SENT: "OTP ಕಳುಹಿಸಲಾಗಿದೆ",
    LOGIN_MESSAGE_INVALID_OTP: "ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ OTP ಅನ್ನು ಒದಗಿಸಿ",
    LOGIN_MESSAGE_OTP_ERROR: "OTP ಕಳುಹಿಸುವಾಗ ಏನೋ ತಪ್ಪಾಗಿದೆ",
    LOGIN_MESSAGE_OTP_SUBMIT_ERROR: "OTP ಸಲ್ಲಿಸುವಾಗ ಏನೋ ತಪ್ಪಾಗಿದೆ",
    LOGIN_MESSAGE_INVALID_DATA: "ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ ಡೇಟಾವನ್ನು ಭರ್ತಿ ಮಾಡಿ",
    SCAN_HEADING_TEETH: "ಹಲ್ಲುಗಳು",
    SCAN_INSTRUCTIONS_MAP: {
      front: [
        "ಮಾರ್ಗದರ್ಶಿ ವೀಡಿಯೊವನ್ನು ಎಚ್ಚರಿಕೆಯಿಂದ ವೀಕ್ಷಿಸಿ",
        "ಚೌಕಟ್ಟಿನಲ್ಲಿ ನಿಮ್ಮ ಮುಂಭಾಗದ ಕೋನದ ದವಡೆಯನ್ನು ಹೊಂದಿಸಿ",
        "ಕ್ಲಿಕ್ ಬಟನ್ ಆಯ್ಕೆಮಾಡಿ",
      ],
      upper: [
        "ಮಾರ್ಗದರ್ಶಿ ವೀಡಿಯೊವನ್ನು ಎಚ್ಚರಿಕೆಯಿಂದ ವೀಕ್ಷಿಸಿ",
        "ಚೌಕಟ್ಟಿನಲ್ಲಿ ನಿಮ್ಮ ಮೇಲಿನ ಕೋನದ ದವಡೆಯನ್ನು ಹೊಂದಿಸಿ",
        "ಕ್ಲಿಕ್ ಬಟನ್ ಆಯ್ಕೆಮಾಡಿ",
      ],
      lower: [
        "ಮಾರ್ಗದರ್ಶಿ ವೀಡಿಯೊವನ್ನು ಎಚ್ಚರಿಕೆಯಿಂದ ವೀಕ್ಷಿಸಿ",
        "ಚೌಕಟ್ಟಿನಲ್ಲಿ ನಿಮ್ಮ ಕೆಳ ಕೋನದ ದವಡೆಯನ್ನು ಹೊಂದಿಸಿ",
        "ಕ್ಲಿಕ್ ಬಟನ್ ಆಯ್ಕೆಮಾಡಿ",
      ],
    },
    SCAN_VIDEO_INSTRUCTIONS_MAP: {
      data: [
        "ಮಾರ್ಗದರ್ಶಿ ವೀಡಿಯೊವನ್ನು ಎಚ್ಚರಿಕೆಯಿಂದ ವೀಕ್ಷಿಸಿ",
        "ಸಾಧ್ಯವಾದಷ್ಟು ಹತ್ತಿರ ನಿಂತುಕೊಳ್ಳಿ. ವೀಡಿಯೊದಲ್ಲಿ ತೋರಿಸಿರುವಂತೆ ನಿಮ್ಮ ಹಲ್ಲುಗಳನ್ನು ತೋರಿಸಿ.",
        "ಎಲ್ಲಾ 3 ಕೋನಗಳನ್ನು ರೆಕಾರ್ಡ್ ಮಾಡಿದ ನಂತರ ವಿರಾಮ ಬಟನ್ ಕ್ಲಿಕ್ ಮಾಡಿ.",
      ],
    },
    SCAN_SUBMIT_BUTTON: "ಹಲ್ಲುಗಳನ್ನು ಸ್ಕ್ಯಾನ್ ಮಾಡಿ",
    ANGLE_LIST: ["ಮುಂಭಾಗ", "ಮೇಲ್ಭಾಗ", "ಕಡಿಮೆ"],
    VIDEO_MODE: "ವೀಡಿಯೊ ರೆಕಾರ್ಡ್ ಮಾಡಿ",
    REPORT_HEADING: "ನಿಮ್ಮ ದಂತ ವರದಿ",
    REPORT_SUB_SECTION_HEADING: "ನಿಮ್ಮ ಅಂದಾಜು ಚಿಕಿತ್ಸಾ ಆಯ್ಕೆಗಳು",
    NO_DETECTIONS_IN_ANGLE:
      "ಯಾವುದೇ ರೋಗಗಳು ಕಂಡುಬಂದಿಲ್ಲ. ಚಿತ್ರವು ಅಸಮರ್ಪಕವಾಗಿದ್ದರೆ, ದಯವಿಟ್ಟು ಮರುಪ್ರಯತ್ನಿಸಿ.",
    BOOK_APPOINTMENT_BUTTON: "ಪುಸ್ತಕ ನೇಮಕಾತಿ",
    DONWLOAD_REPORT_BUTTON: "ವರದಿಯನ್ನು ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
    EXIT_REPORT_BUTTON: "ನಿರ್ಗಮಿಸಿ",
    APPOINTMENT_MESSAGE: "Call this number to book an appointment",
    APPOINTMENT_SECOND_SECTION_HEADING:
      "Fast Track & Evening Clinic at the School of Dental Sciences",
    APPOINTMENT_MORNING_SHIFT_TIMING: "Morning 9 am to Evening 5 pm",
    APPOINTMENT_EVENING_SHIFT_TIMING: "Evening 5 pm to 8.30 pm",
    DOWNLOAD_HEADING1:
      "ಸರಿಯಾದ ಮೌಖಿಕ ಆರೋಗ್ಯ ಅಭ್ಯಾಸಗಳು ಮತ್ತು ನೈರ್ಮಲ್ಯ ಅಭ್ಯಾಸಗಳನ್ನು ಅನುಸರಿಸಲು DentalDost ಅಪ್ಲಿಕೇಶನ್ ಬಳಸಿ",
    DOWNLOAD_HEADING2: "ಈ QR ಕೋಡ್ ಅನ್ನು ಸ್ಕ್ಯಾನ್ ಮಾಡಿ ಮತ್ತು ಈಗ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
    START_SCAN: "ಸ್ಕ್ಯಾನ್ ಪ್ರಾರಂಭಿಸಿ",
    CAMERA_ADJUSTING_TEXT:
      "ಕ್ಯಾಮರಾ ಸ್ವಯಂಚಾಲಿತವಾಗಿ ನಿಮ್ಮ ಎತ್ತರಕ್ಕೆ ಸರಿಹೊಂದಿಸುತ್ತದೆ",
    CLICK_ADJUST: "ಎತ್ತರವನ್ನು ಹೊಂದಿಸಿ",
    FRONT_TEETH: "ಮುಂಭಾಗದ ಹಲ್ಲುಗಳು",
    UPPER_TEETH: "ಮೇಲಿನ ಹಲ್ಲುಗಳು",
    LOWER_TEETH: "ಕೆಳಗಿನ ಹಲ್ಲುಗಳು",
    PLEASE_WAIT: "ದಯಮಾಡಿ ನಿರೀಕ್ಷಿಸಿ ...",
    KNOW_MORE: "ರೋಗಗಳ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿಯಿರಿ",
    SEND_ON_WHATSAPP_BUTTON: "WhatsApp ನಲ್ಲಿ ಕಳುಹಿಸಿ",
    SEND_TO_DOCTOR_BUTTON: "ವೈದ್ಯರಿಗೆ ಕಳುಹಿಸಿ",
  },
  marathi: {
    HOME_HEADING: "तुमचे हसू सुरक्षित करण्याचा एक सोपा, स्मार्ट मार्ग",
    HOME_SUBHEADING: "भाषा निवडा",
    LOGIN_HEADING: "नमस्कार!",
    LOGIN_SUBHEADING: "चला निरोगी तोंडाकडे आपला प्रवास सुरू करूया.",
    LOGIN_INPUT_NAME: "नाव",
    LOGIN_INPUT_MOBILE: "मोबाईल नंबर",
    LOGIN_INPUT_OTP: "OTP",
    LOGIN_BUTTON_SEND_OTP: "OTP पाठवा",
    LOGIN_USER_BUTTON: "लॉगिन करा",
    LOGIN_INPUT_PASSWORD: "पासवर्ड",
    LOGIN_INPUT_USERNAME: "Username",
    LOGIN_BUTTON_SUBMIT_OTP: "OTP सबमिट करा",
    LOGIN_BUTTON_RESEND_OTP: "OTP मिळाला नाही? पुन्हा पाठवा",
    LOGIN_BUTTON_EDIT_NUMBER: "क्रमांक संपादित करा", //Edit Number
    LOGIN_MESSAGE_OTP_SENT: "OTP पाठवला", //OTP Sent
    LOGIN_MESSAGE_INVALID_OTP: "कृपया वैध OTP प्रदान करा",
    LOGIN_MESSAGE_OTP_ERROR: "OTP पाठवताना काहीतरी चूक झाली",
    LOGIN_MESSAGE_OTP_SUBMIT_ERROR: "OTP सबमिट करताना काहीतरी चूक झाली",
    LOGIN_MESSAGE_INVALID_DATA: "कृपया वैध डेटा भरा",
    SCAN_HEADING_TEETH: "दात",
    SCAN_INSTRUCTIONS_MAP: {
      front: [
        "मार्गदर्शक व्हिडिओ काळजीपूर्वक पहा",
        "फ्रेममध्ये तुमचा समोरचे दात फिट करा",
        "फोटो काढण्याचे बटण दाबा",
      ],
      upper: [
        "मार्गदर्शक व्हिडिओ काळजीपूर्वक पहा",
        "फ्रेममध्ये तुमचे वरचे दात फिट करा",
        "फोटो काढण्याचे बटण दाबा",
      ],
      lower: [
        "मार्गदर्शक व्हिडिओ काळजीपूर्वक पहा",
        "फ्रेममध्ये तुमचे खालचे दात फिट करा",
        "फोटो काढण्याचे बटण दाबा",
      ],
    },
    SCAN_VIDEO_INSTRUCTIONS_MAP: {
      data: [
        "मार्गदर्शक व्हिडिओ काळजीपूर्वक पहा",
        "कॅमेऱ्याच्या शक्य तितक्या जवळ उभे रहा. व्हिडिओमध्ये दाखवल्याप्रमाणे तुमचे दात दाखवा",
        "सर्व 3 अँगल रेकॉर्ड झाल्यानंतर पॉज बटण क्लिक करा.",
      ],
    },
    SCAN_SUBMIT_BUTTON: "दात स्कॅन करा",
    ANGLE_LIST: ["समोरचे", "वरचे", "खालचे"],
    VIDEO_MODE: "विडिओ रेकॉर्ड करा",
    REPORT_HEADING: "दातांचा रिपोर्ट",
    REPORT_SUB_SECTION_HEADING:
      "रिपोर्टमध्ये आढळलेल्या समस्या आणि अंदाजे उपचार पर्याय",
    NO_DETECTIONS_IN_ANGLE:
      "कोणतीही आजारांची शोध आढळलेली नाही, जर छायाचित्र अयोग्य असेल तर पुन्हा सबमिट करण्याचा प्रयत्न करा.",

    BOOK_APPOINTMENT_BUTTON: "अपॉइंटमेंट बुक करा",
    DONWLOAD_REPORT_BUTTON: "रिपोर्ट डाउनलोड करा",
    EXIT_REPORT_BUTTON: "बाहेर पडा",
    APPOINTMENT_MESSAGE: "अपॉइंटमेंट बुक करण्यासाठी या नंबरवर कॉल करा",
    APPOINTMENT_SECOND_SECTION_HEADING:
      "स्कूल ऑफ डेंटल सायन्सेस येथील फास्ट ट्रॅक व सायंकाळचे क्लिनिक",
    APPOINTMENT_MORNING_SHIFT_TIMING: "सकाळी ९ ते संध्याकाळी ५ पर्यंत",
    APPOINTMENT_EVENING_SHIFT_TIMING: "संध्याकाळी ५ ते ८.३० पर्यंत",
    DOWNLOAD_HEADING1:
      "डेंटलदोस्त ऍप द्वारे मिळावा तुमचा दंत आरोग्य रिपोर्ट आणि योग्य सवयींचे पालन करा",
    DOWNLOAD_HEADING2: "खालील QR कोड स्कॅन करा आणि आत्ताच डाउनलोड करा",
    START_SCAN: "स्कॅन सुरू करा",
    CAMERA_ADJUSTING_TEXT:
      " कॅमेरा आपल्या उंचावर स्वयंसिद्धपणे समायोजित होत आहे",
    CLICK_ADJUST: "उंची समायोजित करा",
    FRONT_TEETH: "मुखपूर्व दात",
    UPPER_TEETH: "वरील दात",
    LOWER_TEETH: "खालील दात",
    PLEASE_WAIT: "कृपया वाट पाहा ...",
    KNOW_MORE: "रोगांच्या बद्दल अधिक जाणून घ्या",
    SEND_ON_WHATSAPP_BUTTON: "व्हाट्सअँपवर पाठवा",
    SEND_TO_DOCTOR_BUTTON: "डॉक्टरकडून पाठवा",
  },

  hindi: {
    HOME_HEADING: "अपने दांतो की रक्षा करने का सब से आसान तरीका",
    HOME_SUBHEADING: "भाषा का चयन करें",
    LOGIN_HEADING: "नमस्ते!",
    LOGIN_SUBHEADING: "आइए एक स्वस्थ मुंह की ओर अपनी यात्रा शुरू करें।",
    LOGIN_INPUT_NAME: "नाम",
    LOGIN_INPUT_MOBILE: "मोबाइल नंबर",
    LOGIN_INPUT_OTP: "OTP",
    LOGIN_BUTTON_SEND_OTP: "OTP भेजें",
    LOGIN_USER_BUTTON: "लॉग इन करें",
    LOGIN_INPUT_PASSWORD: "पासवर्ड",
    LOGIN_INPUT_USERNAME: "उपयोगकर्ता नाम",
    LOGIN_BUTTON_SUBMIT_OTP: "OTP सबमिट करें",
    LOGIN_BUTTON_RESEND_OTP: "OTP नहीं मिला? पुन: भेजें",
    LOGIN_BUTTON_EDIT_NUMBER: "नंबर संपादित करें", //Edit Number
    LOGIN_MESSAGE_OTP_SENT: "OTP भेजा गया",
    LOGIN_MESSAGE_INVALID_OTP: "कृपया वैध OTP प्रदान करें",
    LOGIN_MESSAGE_OTP_ERROR: "OTP भेजते समय कुछ गलत हुआ",
    LOGIN_MESSAGE_OTP_SUBMIT_ERROR: "OTP सबमिट करते समय कुछ गड़बड़ी हुई",
    LOGIN_MESSAGE_INVALID_DATA: "कृपया मान्य डेटा भरें",
    SCAN_HEADING_TEETH: "दांत",
    SCAN_INSTRUCTIONS_MAP: {
      front: [
        "बगल वाला वीडियो को ध्यान से देखें",
        "अपने सामने के जबड़े को फ्रेम में फिट करें",
        "क्लिक बटन को दबाये",
      ],
      upper: [
        "बगल वाला वीडियो को ध्यान से देखें",
        "अपने उपर के के जबड़े को फ्रेम में फिट करें",
        "क्लिक बटन को दबाये",
      ],
      lower: [
        "बगल वाला वीडियो को ध्यान से देखें",
        "अपने नीचे के जबड़े को फ्रेम में फिट करें",
        "क्लिक बटन को दबाये",
      ],
    },
    SCAN_VIDEO_INSTRUCTIONS_MAP: {
      data: [
        "बगल वाला वीडियो को ध्यान से देखें",
        "कैमरे के जितना हो सके पास खड़े रहें। वीडियो में दिखाए अनुसार अपने दांत दिखाएं।",
        "एक बार सभी 3 ऐंगल को रिकॉर्ड करने के बाद पॉज बटन पर क्लिक करें।",
      ],
    },
    SCAN_SUBMIT_BUTTON: "दांतों को स्कैन करें",
    ANGLE_LIST: ["सामनेके", "उपरके", "नीचेके"],
    VIDEO_MODE: "वीडियो रिकॉर्ड करें",
    REPORT_HEADING: "दंत आरोग्य रिपोर्ट",
    REPORT_SUB_SECTION_HEADING: "आपका अनुमानित उपचार विकल्प",
    NO_DETECTIONS_IN_ANGLE:
      "कोई बीमारी नहीं पाई गई, अगर छवि ठीक नहीं है तो कृपया उसे फिर से सबमिट करें।",
    BOOK_APPOINTMENT_BUTTON: "अपॉइंटमेंट बुक करें",
    DONWLOAD_REPORT_BUTTON: "रिपोर्ट डाउनलोड करें",
    EXIT_REPORT_BUTTON: "बाहर पडें",
    APPOINTMENT_MESSAGE: "अपॉइंटमेंट बुक करने के लिए इस नंबर पर कॉल करें",
    APPOINTMENT_SECOND_SECTION_HEADING:
      "स्कूल ऑफ डेंटल साइंसेज का फास्ट ट्रैक और शाम का क्लिनिक",
    APPOINTMENT_MORNING_SHIFT_TIMING: "सुबह ९ से शाम को ५ तक",
    APPOINTMENT_EVENING_SHIFT_TIMING: "शाम को ५ से ८.३० बजे तक",
    DOWNLOAD_HEADING1:
      "डेंटलडॉस्ट ऐप में पाइए आपका दंत आरोग्य रिपोर्ट और सही मौखिक स्वास्थ्य आदतों का पालन करें",
    DOWNLOAD_HEADING2: "इस QR कोड को स्कैन करें और अभी डाउनलोड करें",
    START_SCAN: "स्कैन शुरू करें",
    CAMERA_ADJUSTING_TEXT:
      "कैमरा आपकी ऊंचाई के अनुसार स्वतः समायोजित हो रहा है",
    CLICK_ADJUST: "ऊंचाई समायोजित करें",
    FRONT_TEETH: "आगे के दांत",
    UPPER_TEETH: "ऊपरी दांत",
    LOWER_TEETH: "निचले दांत",
    PLEASE_WAIT: "कृपया प्रतीक्षा करें ...",
    KNOW_MORE: "रोगों के बारे में और जानें",
    SEND_ON_WHATSAPP_BUTTON: "व्हाट्सएप पर भेजें",
    SEND_TO_DOCTOR_BUTTON: "डॉक्टर को भेजें",
  },

  english: {
    HOME_HEADING: "Simplest way to protect your teeth",
    HOME_SUBHEADING: "Select your preferred language",
    LOGIN_HEADING: "Hi There!",
    LOGIN_SUBHEADING:
      "Let’s get your journey started towards a healthier mouth.",
    LOGIN_INPUT_NAME: "Name",
    LOGIN_INPUT_MOBILE: "Mobile Number",
    LOGIN_INPUT_OTP: "OTP",
    LOGIN_BUTTON_SEND_OTP: "Send OTP",
    LOGIN_USER_BUTTON: "Login",
    LOGIN_INPUT_PASSWORD: "Password",
    LOGIN_INPUT_USERNAME: "Username",
    LOGIN_BUTTON_SUBMIT_OTP: "Submit OTP",
    LOGIN_BUTTON_RESEND_OTP: "Didn’t get OTP? Resend",
    LOGIN_BUTTON_EDIT_NUMBER: "Edit Number",
    LOGIN_MESSAGE_OTP_SENT: "OTP Sent",
    LOGIN_MESSAGE_INVALID_OTP: "Please provide valid OTP",
    LOGIN_MESSAGE_OTP_ERROR: "Something went wrong while sending OTP",
    LOGIN_MESSAGE_OTP_SUBMIT_ERROR: "Something went wrong while submitting OTP",
    LOGIN_MESSAGE_INVALID_DATA: "Please fill valid data",
    SCAN_HEADING_TEETH: "Teeth",
    SCAN_INSTRUCTIONS_MAP: {
      front: [
        "Watch the guiding video carefully",
        "Fit your front angle jaw in the frame",
        "Select the click button",
      ],
      upper: [
        "Watch the guiding video carefully",
        "Fit your upper angle jaw in the frame",
        "Select the click button",
      ],
      lower: [
        "Watch the guiding video carefully",
        "Fit your lower angle jaw in the frame",
        "Select the click button",
      ],
    },
    SCAN_VIDEO_INSTRUCTIONS_MAP: {
      data: [
        "Watch the guiding video carefully",
        "Stand as close as possible. Show your teeth as shown in the video.",
        "Click pause button once all 3 angles are recorded.",
      ],
    },
    SCAN_SUBMIT_BUTTON: "Scan teeth",
    ANGLE_LIST: ["Front", "Upper", "Lower"],
    VIDEO_MODE: "Record Video",
    REPORT_HEADING: "Your Dental Report",
    REPORT_SUB_SECTION_HEADING: "Your Estimated Treatment Options",
    NO_DETECTIONS_IN_ANGLE:
      "No diseases found. If the image is improper, please retry.",
    BOOK_APPOINTMENT_BUTTON: "Book Appointment",
    DONWLOAD_REPORT_BUTTON: "Download Report",
    EXIT_REPORT_BUTTON: "EXIT",
    APPOINTMENT_MESSAGE: "Call this number to book an appointment",
    APPOINTMENT_SECOND_SECTION_HEADING:
      "Fast Track & Evening Clinic at the School of Dental Sciences",
    APPOINTMENT_MORNING_SHIFT_TIMING: "Morning 9 am to Evening 5 pm",
    APPOINTMENT_EVENING_SHIFT_TIMING: "Evening 5 pm to 8.30 pm",
    DOWNLOAD_HEADING1:
      "Use DentalDost app to follow right oral health habits and hygiene practices",
    DOWNLOAD_HEADING2: "Scan this QR code and Download Now",
    START_SCAN: "START SCAN",
    CAMERA_ADJUSTING_TEXT: "Camera is automatically adjusting to your height",
    CLICK_ADJUST: "adjust height",
    FRONT_TEETH: "FRONT TEETH",
    UPPER_TEETH: "UPPER TEETH",
    LOWER_TEETH: "LOWER TEETH",
    PLEASE_WAIT: "Please Wait ...",
    KNOW_MORE: "Know more about the diseases",
    SEND_ON_WHATSAPP_BUTTON: "Send on WhatsApp",
    SEND_TO_DOCTOR_BUTTON: "Send to Doctor",
  },
  tamil: {
    HOME_HEADING: "உங்கள் பற்களை பாதுகாக்க எளிய வழி",
    HOME_SUBHEADING: "மொழியைத் தேர்ந்தெடுக்கவும்",
    LOGIN_HEADING: "வணக்கம்!",
    LOGIN_SUBHEADING: "ஆரோக்கியமான வாயை நோக்கி உங்கள் பயணத்தைத் தொடங்குவோம்.",
    LOGIN_INPUT_NAME: "பெயர்",
    LOGIN_INPUT_MOBILE: "Mobile Number",
    LOGIN_INPUT_OTP: "OTP",
    LOGIN_BUTTON_SEND_OTP: "அனுப்பு OTP",
    LOGIN_USER_BUTTON: "உள்நுழைய",
    LOGIN_INPUT_PASSWORD: "கடவுச்சொல்",
    LOGIN_INPUT_USERNAME: "பயனர் பெயர்",
    LOGIN_BUTTON_SUBMIT_OTP: "Submit OTP",
    LOGIN_BUTTON_RESEND_OTP: "Didn’t get OTP? Resend",
    LOGIN_BUTTON_EDIT_NUMBER: "Edit Number",
    LOGIN_MESSAGE_OTP_SENT: "OTP Sent",
    LOGIN_MESSAGE_INVALID_OTP: "Please provide valid OTP",
    LOGIN_MESSAGE_OTP_ERROR: "அனுப்பும்போது ஏதோ தவறு ஏற்பட்டது OTP",
    LOGIN_MESSAGE_OTP_SUBMIT_ERROR:
      "சமர்ப்பிக்கும் போது ஏதோ தவறு ஏற்பட்டது OTP",
    LOGIN_MESSAGE_INVALID_DATA: "சரியான தரவை நிரப்பவும்",
    SCAN_HEADING_TEETH: "பற்கள்",
    SCAN_INSTRUCTIONS_MAP: {
      front: [
        "வழிகாட்டி வீடியோவை கவனமாக பாருங்கள்",
        "சட்டத்தில் உங்கள் முன் கோண தாடை பொருத்தவும்",
        "கிளிக் பொத்தானைத் தேர்ந்தெடுக்கவும்",
      ],
      upper: [
        "வழிகாட்டி வீடியோவை கவனமாக பாருங்கள்",
        "சட்டத்தில் உங்கள் மேல் கோண தாடை பொருத்தவும்",
        "Select the click button",
      ],
      lower: [
        "வழிகாட்டி வீடியோவை கவனமாக பாருங்கள்",
        "உங்கள் கீழ் கோண தாடையை சட்டகத்தில் பொருத்தவும்",
        "கிளிக் பொத்தானைத் தேர்ந்தெடுக்கவும்",
      ],
    },
    SCAN_VIDEO_INSTRUCTIONS_MAP: {
      data: [
        "வழிகாட்டும் வீடியோவை கவனமாகப் பாருங்கள்",
        "முடிந்தவரை நெருக்கமாக நிற்கவும். வீடியோவில் காட்டப்பட்டுள்ளபடி உங்கள் பற்களைக் காட்டுங்கள்.",
        "அனைத்து 3 கோணங்களும் பதிவு செய்யப்பட்டவுடன் இடைநிறுத்த பொத்தானைக் கிளிக் செய்யவும்.",
      ],
    },
    SCAN_SUBMIT_BUTTON: "பல் ஸ்கேன்",
    ANGLE_LIST: ["முகம்", "மேல்", "கீழ்"],
    VIDEO_MODE: "வீடியோ பதிவு",
    REPORT_HEADING: "உங்கள் பல அறிகுறி",
    REPORT_SUB_SECTION_HEADING:
      "உங்கள் மருத்துவ விருப்பங்கள் மதிப்பிடப்பட்டுள்ளன",
    NO_DETECTIONS_IN_ANGLE:
      "வியாதிகள் காணப்படவில்லை. படம் தவறானால், மீண்டும் முயற்சி செய்யவும்.",
    BOOK_APPOINTMENT_BUTTON: "முகாம் பதிவு",
    DONWLOAD_REPORT_BUTTON: "அறிகுறி பதிவிறக்கம்",
    EXIT_REPORT_BUTTON: "வெளியேறு",
    APPOINTMENT_MESSAGE: "முகாம் பதிவுக்கு இந்த எண்ணை அழைக்கவும்",
    APPOINTMENT_SECOND_SECTION_HEADING:
      "பல அறிவியல் பள்ளியில் வேக ட்ராக் & மாலை கிளினிக்",
    APPOINTMENT_MORNING_SHIFT_TIMING: "காலை 9 மணி முதல் மாலை 5 மணி வரை",
    APPOINTMENT_EVENING_SHIFT_TIMING: "மாலை 5 மணி முதல் 8.30 மணி வரை",
    DOWNLOAD_HEADING1:
      "சரியான வாய்வழி சுகாதார பழக்கம் மற்றும் சுகாதார நடைமுறைகளைப் பின்பற்ற DentalDost பயன்பாட்டைப் பயன்படுத்தவும்",
    DOWNLOAD_HEADING2: "இந்த QR குறியீட்டை ஸ்கேன் செய்து இப்போது பதிவிறக்கவும்",
    START_SCAN: "ஸ்கேன் ஆரம்பிக்கவும்",
    CAMERA_ADJUSTING_TEXT: "கேமரா உங்கள் உயரத்துக்கு தானாக செம்மைபடுகிறது",
    CLICK_ADJUST: "உயரம் செய்",
    FRONT_TEETH: "முக பல்",
    UPPER_TEETH: "மேல் பல்",
    LOWER_TEETH: "கீழ் பல்",
    PLEASE_WAIT: "காத்திருக்கவும் ...",
    KNOW_MORE: "பிரச்சினைகள் பற்றிய மேலும் அறிந்து கொள்ளுங்கள்",
    SEND_ON_WHATSAPP_BUTTON: "வாட்ஸாப்பில் அனுப்பு",
    SEND_TO_DOCTOR_BUTTON: "டாக்டருக்கு அனுப்பு",
  },
  telugu: {
    HOME_HEADING: "మీ దంతాలను సురక్షితంగా ఉంచే అత్యంత సరళ మార్గం",
    HOME_SUBHEADING: "భాషను ఎంచుకోండి",
    LOGIN_HEADING: "హలో! ఇక్కడ రాండి",
    LOGIN_SUBHEADING: "మీ దంత ఆరోగ్యంపై ప్రయాణం ప్రారంభించండి",
    LOGIN_INPUT_NAME: "పేరు",
    LOGIN_INPUT_MOBILE: "మొబైల్ సంఖ్య",
    LOGIN_INPUT_OTP: "OTP",
    LOGIN_BUTTON_SEND_OTP: "OTP పంపు",
    LOGIN_USER_BUTTON: "లాగిన్",
    LOGIN_INPUT_PASSWORD: "పాస్వర్డ్",
    LOGIN_INPUT_USERNAME: "వాడుకరి పేరు",
    LOGIN_BUTTON_SUBMIT_OTP: "OTP సమర్పించు",
    LOGIN_BUTTON_RESEND_OTP: "OTP రాలేదు? మళ్ళీ పంపు",
    LOGIN_BUTTON_EDIT_NUMBER: "సంఖ్యను మార్చు",
    LOGIN_MESSAGE_OTP_SENT: "OTP పంపబడింది",
    LOGIN_MESSAGE_INVALID_OTP: "తప్పు OTP, దయచేసి సరిగ్గా నమోదు చేయండి",
    LOGIN_MESSAGE_OTP_ERROR: "OTP పంపడంలో లోపం ఉంది",
    LOGIN_MESSAGE_OTP_SUBMIT_ERROR: "OTP సమర్పణలో లోపం ఉంది",
    LOGIN_MESSAGE_INVALID_DATA: "తప్పుడు డేటా, దయచేసి సరిచేయండి",
    SCAN_HEADING_TEETH: "దంతాలు",
    SCAN_INSTRUCTIONS_MAP: {
      front: [
        "మార్గదర్శక వీడియోను జాగ్రత్తగా చూడండి",
        "ముందునుంచి మీ దంతాలను ఫ్రేములో పెట్టండి",
        "క్లిక్ బటన్ నొక్కండి",
      ],
      upper: [
        "మార్గదర్శక వీడియోను కనపడుటకు సావధానంగా చూడండి",
        "మీ పైన కోణ చెక్ లో మీ పైన కోన జాబితలో సరైనట్టున్నది",
        "క్లిక్ బటన్‌ను ఎంచుకోండి",
      ],
      lower: [
        "మార్గదర్శక వీడియోను కనపడుటకు సావధానంగా చూడండి",
        "మీ క్రింద కోణ చెక్ లో మీ క్రింద కోన జాబితలో సరైనట్టున్నది",
        "క్లిక్ బటన్‌ను ఎంచుకోండి",
      ],
    },
    SCAN_VIDEO_INSTRUCTIONS_MAP: {
      data: [
        "మార్గదర్శక వీడియోను కనపడుటకు సావధానంగా చూడండి",
        "అత్యంత సమీపంగా నిండండి. వీడియోలో చూపించినట్టున్నట్టున్న రెకార్డింగ్ మూడు కోణాలు రికార్డ్ అయ్యేలా చేయండి.",
      ],
    },
    SCAN_SUBMIT_BUTTON: "దంతాలను స్కాన్ చేయండి",
    ANGLE_LIST: ["ముందు", "పైన", "క్రింద"],
    VIDEO_MODE: "వీడియో రికార్డ్",
    REPORT_HEADING: "మీ డెంటల్ నివేదిక",
    REPORT_SUB_SECTION_HEADING: "మీ అంచనా చేసే అంశాల అంశాలను",
    NO_DETECTIONS_IN_ANGLE:
      "ఏ రోగాలు కనపడలేదు. ఇమేజ్ తప్పుగా ఉంటే, దయచేసి మళ్ళీ ప్రయత్నించండి.",
    BOOK_APPOINTMENT_BUTTON: "అపాయింట్మెంట్ బుక్ చేయండి",
    DONWLOAD_REPORT_BUTTON: "నివేదికను డౌన్లోడ్ చేయండి",
    EXIT_REPORT_BUTTON: "నివేదికను బయటకు వెళ్ళండి",
    APPOINTMENT_MESSAGE: "ఈ నంబర్‌ను అపాయింట్మెంట్ బుక్ చేయడానికి కాల్ చేయండి",
    APPOINTMENT_SECOND_SECTION_HEADING:
      "డెంటల్ సైన్సెస్ స్కూల్లో ఫాస్ట్ ట్రాక్ & ఈవెనింగ్ క్లినిక్",
    APPOINTMENT_MORNING_SHIFT_TIMING: "మరింగ్ 9 గంటల నుంచి ఈవెనింగ్ 5 గంటలకు",
    APPOINTMENT_EVENING_SHIFT_TIMING: "ఈవెనింగ్ 5 గంటల నుంచి 8.30 గంటలకు",
    DOWNLOAD_HEADING1:
      "DentalDost యాప్‌ను ఉపయోగించి సరైన ఓరల్ ఆరోగ్య అలవాట్లు మరియు శుచిత్వ ప్రాక్టీసులను అనుసరించండి",
    DOWNLOAD_HEADING2: "ఈ క్యూఆర్ కోడ్‌ను స్కాన్ చేసి ఇప్పుడు డౌన్‌లోడ్ చేయండి",
    START_SCAN: "స్కాన్ ప్రారంభించండి",
    CAMERA_ADJUSTING_TEXT: "కెమెరా మీ ఎత్తుకి ఆటోమెటిక్‌గా అడజస్ట్ అవుతోంది",
    CLICK_ADJUST: "ఎత్తు సరిచేయండి",
    FRONT_TEETH: "ముందు పల్లు",
    UPPER_TEETH: "పైన పల్లు",
    LOWER_TEETH: "కింద పల్లు",
    PLEASE_WAIT: "దయచేసి వేచి ఉండండి ...",
    KNOW_MORE: "వ్యాధుల గురించి మరింత తెలుసుకోండి",
    SEND_ON_WHATSAPP_BUTTON: "వాట్సాప్‌పై పంపండి",
    SEND_TO_DOCTOR_BUTTON: "డాక్టర్‌కి పంపండి",
  },

  gujarati: {
    HOME_HEADING: "તમારા દાંતોને સુરક્ષિત રાખવાનો સૌથી સરળ ઉપાય",
    HOME_SUBHEADING: "તમારી પસંદગીની ભાષા પસંદ કરો",
    LOGIN_HEADING: "હાય ત્યાં!",
    LOGIN_SUBHEADING: "આવો તમારી તંદુરસ્ત મોઢાની તરફની યાત્રા શરૂ કરીએ.",
    LOGIN_INPUT_NAME: "નામ",
    LOGIN_INPUT_MOBILE: "મોબાઇલ નંબર",
    LOGIN_INPUT_OTP: "OTP",
    LOGIN_BUTTON_SEND_OTP: "OTP મોકલો",
    LOGIN_USER_BUTTON: "લૉગિન",
    LOGIN_INPUT_PASSWORD: "પાસવર્ડ",
    LOGIN_INPUT_USERNAME: "યુઝરનેમ",
    LOGIN_BUTTON_SUBMIT_OTP: "OTP સબમિટ કરો",
    LOGIN_BUTTON_RESEND_OTP: "OTP મળ્યો નથી? ફરી મોકલો",
    LOGIN_BUTTON_EDIT_NUMBER: "નંબર સુધારો",
    LOGIN_MESSAGE_OTP_SENT: "OTP મોકલાયો",
    LOGIN_MESSAGE_INVALID_OTP: "કૃપા કરી માન્ય OTP પૂરો પાડો",
    LOGIN_MESSAGE_OTP_ERROR: "OTP મોકલવામાં કંઇક ખોટું થયું",
    LOGIN_MESSAGE_OTP_SUBMIT_ERROR: "OTP સબમિટ કરવામાં કંઇક ખોટું થયું",
    LOGIN_MESSAGE_INVALID_DATA: "કૃપા કરી માન્ય ડેટા ભરો",
    SCAN_HEADING_TEETH: "દાંત",
    SCAN_INSTRUCTIONS_MAP: {
      front: [
        "સૂચના વિડીઓ ધ્યાનથી જુઓ",
        "તમારું ફ્રન્ટ એંગલ જડબું ફ્રેમમાં ફિટ કરો",
        "ક્લિક બટન પસંદ કરો",
      ],
      upper: [
        "સૂચના વિડીઓ ધ્યાનથી જુઓ",
        "તમારું ઉપરનું એંગલ જડબું ફ્રેમમાં ફિટ કરો",
        "ક્લિક બટન પસંદ કરો",
      ],
      lower: [
        "સૂચના વિડીઓ ધ્યાનથી જુઓ",
        "તમારું નીચેનું એંગલ જડબું ફ્રેમમાં ફિટ કરો",
        "ક્લિક બટન પસંદ કરો",
      ],
    },
    SCAN_VIDEO_INSTRUCTIONS_MAP: {
      data: [
        "સૂચના વિડીઓ ધ્યાનથી જુઓ",
        "જેટલું શક્ય હોય તેટલું નજીક ઉભા રહો. વિડીઓમાં દર્શાવેલ મુજબ તમારા દાંતો બતાવો.",
        "એકવાર ત્રણેય એંગલ્સ રેકોર્ડ થઇ જાય ત્યારે પૉઝ બટન દબાવો.",
      ],
    },
    SCAN_SUBMIT_BUTTON: "દાંત સ્કેન કરો",
    ANGLE_LIST: ["ફ્રન્ટ", "ઉપર", "નીચે"],
    VIDEO_MODE: "વિડીઓ રેકોર્ડ કરો",
    REPORT_HEADING: "તમારો દાંતનો રિપોર્ટ",
    REPORT_SUB_SECTION_HEADING: "તમારા અંદાજીત સારવાર વિકલ્પો",
    NO_DETECTIONS_IN_ANGLE:
      "કોઈ રોગો શોધાયા નથી. જો છબી અયોગ્ય હોય તો, કૃપા કરી ફરી પ્રયાસ કરો.",
    BOOK_APPOINTMENT_BUTTON: "એપોઈન્ટમેન્ટ બુક કરો",
    DONWLOAD_REPORT_BUTTON: "રિપોર્ટ ડાઉનલોડ કરો",
    EXIT_REPORT_BUTTON: "બહાર નીકળો",
    APPOINTMENT_MESSAGE: "અપોઈન્ટમેન્ટ બુક કરવા માટે આ નંબર પર કૉલ કરો",
    APPOINTMENT_SECOND_SECTION_HEADING:
      "ડેન્ટલ સાયન્સ સ્કૂલમાં ફાસ્ટ ટ્રેક અને સાંજની ક્લિનિક",
    APPOINTMENT_MORNING_SHIFT_TIMING: "સવારના 9 થી સાંજના 5 સુધી",
    APPOINTMENT_EVENING_SHIFT_TIMING: "સાંજના 5 થી 8.30 સુધી",
    DOWNLOAD_HEADING1:
      "યોગ્ય મૌખિક સ્વાસ્થ્ય ટેવો અને સ્વચ્છતા પ્રથાઓ અનુસરવા માટે DentalDost એપ વાપરો",
    DOWNLOAD_HEADING2: "આ QR કોડ સ્કેન કરો અને હવે ડાઉનલોડ કરો",
    START_SCAN: "સ્કેન શરૂ કરો",
    CAMERA_ADJUSTING_TEXT: "કેમેરો આપોઆપ તમારી ઊંચાઈ સાથે ગોઠવાઈ રહ્યો છે",
    CLICK_ADJUST: "ઊંચાઈ ગોઠવો",
    FRONT_TEETH: "સામેના દાંત",
    UPPER_TEETH: "ઉપરના દાંત",
    LOWER_TEETH: "નીચેના દાંત",
    PLEASE_WAIT: "કૃપયા રાહ જુઓ ...",
    KNOW_MORE: "રોગો વિશે વધુ જાણો",
    SEND_ON_WHATSAPP_BUTTON: "WhatsApp પર મોકલો",
    SEND_TO_DOCTOR_BUTTON: "ડોક્ટરને મોકલો",
  },

  doctorsMap: {
    1: {
      english: { name: "Dr.Sushma Bhandari", qualification: "BDS, MDS" },
      marathi: { name: "डॉ. सुषमा भंडारी", qualification: "बीडीएस, एमडीएस" },
      hindi: { name: "डॉ. सुषमा भंडारी", qualification: "बीडीएस, एमडीएस" },
    },
    2: {
      english: { name: "Dr.Rashmi Gangavati", qualification: "BDS, MDS" },
      marathi: { name: "डॉ. रश्मी गंगावती", qualification: "बीडीएस, एमडीएस" },
      hindi: { name: "डॉ. रश्मी गंगावती", qualification: "बीडीएस, एमडीएस" },
    },
    3: {
      english: { name: "Dr. Akhil Rao", qualification: "BDS, MDS" },
      marathi: { name: "डॉ. अखिल राव", qualification: "बीडीएस, एमडीएस" },
      hindi: { name: "डॉ. अखिल राव", qualification: "बीडीएस, एमडीएस" },
    },
    4: {
      english: { name: "Dr.Sameer Zope", qualification: "BDS, MDS" },
      marathi: { name: "डॉ. समीर झोपे", qualification: "बीडीएस, एमडीएस" },
      hindi: { name: "डॉ. समीर झोपे", qualification: "बीडीएस, एमडीएस" },
    },
    5: {
      english: { name: "Dr.Chinju Punnen", qualification: "BDS, MDS" },
      marathi: { name: "डॉ. चिंजू पुननेन", qualification: "बीडीएस, एमडीएस" },
      hindi: { name: "डॉ. चिंजू पुननेन", qualification: "बीडीएस, एमडीएस" },
    },
    6: {
      english: { name: "Dr.Sonali Waghmode", qualification: "BDS, MDS" },
      marathi: { name: "डॉ. सोनाली वाघमोडे", qualification: "बीडीएस, एमडीएस" },
      hindi: { name: "डॉ. सोनाली वाघमोडे", qualification: "बीडीएस, एमडीएस" },
    },
    7: {
      english: { name: "Dr. Shubha Joshi", qualification: "BDS, MDS" },
      marathi: { name: "डॉ. शुभा जोशी", qualification: "बीडीएस, एमडीएस" },
      hindi: { name: "डॉ. शुभा जोशी", qualification: "बीडीएस, एमडीएस" },
    },
    8: {
      english: { name: "Dr.Nitesh Akela", qualification: "BDS, MDS" },
      marathi: { name: "डॉ. नितेश अकेला", qualification: "बीडीएस, एमडीएस" },
      hindi: { name: "डॉ. नितेश अकेला", qualification: "बीडीएस, एमडीएस" },
    },
  },

  diseasesMap: {
    "pit and fissure caries": {
      color: "#0000FF",
      english: {
        name: "Pit and fissure caries",
        description: "Start of a tooth decay",
        treatment: "Tooth like colored filling",
        price: "₹ 200 - 350",
      },
      kannada: {
        name: "ಪಿಟ್ ಮತ್ತು ಬಿರುಕು ಕ್ಷಯ",
        description: "ಹಲ್ಲಿನ ಕ್ಷಯದ ಪ್ರಾರಂಭ",
        treatment: "ಬಣ್ಣದ ತುಂಬುವಿಕೆಯಂತಹ ಹಲ್ಲು",
        price: "₹ 200 - 350",
      },
      marathi: {
        name: "थोडा कीड",
        description: "दात किडण्याची प्रारंभिक सुरुवात",
        treatment: "दाता-समान रंग असणारी फीलिन्ग",
        price: "₹ 200 - 350",
      },
      hindi: {
        name: "शुरुआती कीड़",
        description: "कीड़ की शुरुआत",
        treatment: "दाँत के रंग का फिलिंग",
        price: "₹ 200 - 350",
      },
      tamil: {
        name: "குழி மற்றும் பிளவு பூச்சிகள்",
        description: "பல் சிதைவின் ஆரம்பம்",
        treatment: "வண்ண நிரப்புதல் போன்ற பல்",
        price: "₹ 200 - 350",
      },
      telugu: {
        name: "పిట్ మరియు ఫిషర్ క్షయం",
        description: "దంత క్షయం ప్రారంభం",
        treatment: "రంగు పూరకం వంటి దంతాలు",
        price: "₹ 200 - 350",
      },
      gujarati: {
        name: "ખાડો અને નકામું કામચલાઉ",
        description: "દાંતના સડોની શરૂઆત",
        treatment: "રંગ ભરવા જેવા દાંત",
        price: "₹ 200 - 350",
      },
    },
    "deep caries": {
      color: "#FF8300",
      english: {
        name: "Deep caries",
        description: "A deeper cavity that might be painful",
        treatment:
          "Deep filling/ Root canal treatment according to Xray finding",
        price: "₹ 500 - 1140",
      },
      kannada: {
        name: "ಆಳವಾದ ಕ್ಷಯ",
        description: "ನೋವಿನಿಂದ ಕೂಡಿದ ಆಳವಾದ ಕುಳಿ",
        treatment: "ಎಕ್ಸರೇ ಶೋಧನೆಯ ಪ್ರಕಾರ ಡೀಪ್ ಫಿಲ್ಲಿಂಗ್/ ರೂಟ್ ಕೆನಾಲ್ ಚಿಕಿತ್ಸೆ",
        price: "₹ 500 - 1140",
      },
      marathi: {
        name: "खोल कीड",
        description: "खोल कीड जी वेदनादायक असू शकते",
        treatment:
          "रूट कॅनॉल ट्रीटमेंट/फीलिन्ग करणे हे xray तपासणीवर अवलंबून आहे",
        price: "₹ 500 - 1140",
      },
      hindi: {
        name: "गहरा कीड़",
        description: "एक गहरा कीड़ा जो दर्दनाक हो सकता है",
        treatment: "ग़हरा फिलिंग/रूट कैनाल उपचार एक्सरे फाइंडिंग के अनुसार",
        price: "₹ 500 - 1140",
      },
      tamil: {
        name: "ஆழமான பூச்சிகள்",
        description: "வலிமிகுந்த ஆழமான குழி",
        treatment:
          "எக்ஸ்ரே கண்டுபிடிப்பின் படி ஆழமான நிரப்புதல் / ரூட் கால்வாய் சிகிச்சை",
        price: "₹ 500 - 1140",
      },
      telugu: {
        name: "లోతైన క్షయం",
        description: "బాధాకరంగా ఉండే లోతైన కుహరం",
        treatment:
          "ఎక్స్రే నిర్ధారణ ప్రకారం డీప్ ఫిల్లింగ్/ రూట్ కెనాల్ చికిత్స",
        price: "₹ 500 - 1140",
      },
      gujarati: {
        name: "Deepંડા અસ્થિભંગ",
        description: "એક er ંડા પોલાણ કે જે દુ painful ખદાયક હોઈ શકે",
        treatment: "XRAY શોધવા મુજબ ડીપ ફિલિંગ/ રુટ કેનાલ ટ્રીટમેન્ટ",
        price: "₹ 500 - 1140",
      },
    },
    stains: {
      color: "#FF0000",
      english: {
        name: "Stains",
        description: "Brownish to blackish discoloration",
        treatment: "Teeth cleaning and polishing",
        price: "₹ 150",
      },
      kannada: {
        name: "ಕಲೆಗಳು",
        description: "ಕಂದು ಬಣ್ಣದಿಂದ ಕಪ್ಪು ಬಣ್ಣದ ಬಣ್ಣ",
        treatment: "ಹಲ್ಲುಗಳನ್ನು ಸ್ವಚ್ಛಗೊಳಿಸುವುದು ಮತ್ತು ಹೊಳಪು ಮಾಡುವುದು",
        price: "₹ 150",
      },
      marathi: {
        name: "दातांवर डाग",
        description: "तपकिरी ते काळ्या रंगाचा दाग",
        treatment: "दात स्वच्छ करणे",
        price: "₹ 150",
      },
      hindi: {
        name: "दांतों पर दाग",
        description: "भूरे से काले रंग का मलिनकिरण",
        treatment: "दांतो को सफाई",
        price: "₹ 150",
      },
      tamil: {
        name: "கறைகள்",
        description: "பழுப்பு நிறத்தில் இருந்து கருப்பு நிறமாக மாறுதல்",
        treatment: "பற்களை சுத்தம் செய்தல் மற்றும் பாலிஷ் செய்தல்",
        price: "₹ 150",
      },
      telugu: {
        name: "మరకలు",
        description: "గోధుమ రంగు నుండి నలుపు రంగు మారడం",
        treatment: "పళ్ళు శుభ్రపరచడం మరియు పాలిష్ చేయడం",
        price: "₹ 150",
      },
      gujarati: {
        name: "ડાઘ",
        description: "ભૂરાશથી કાળા રંગનો વિકૃતિકરણ",
        treatment: "દાંત સફાઈ અને પોલિશિંગ",
        price: "₹ 150",
      },
    },
    calculus: {
      color: "#00FF00",
      english: {
        name: "Calculus",
        description: "Hard yellowish-white deposits on teeth/gums",
        treatment: "Deep Scaling",
        price: "₹ 150",
      },
      kannada: {
        name: "ಕಲನಶಾಸ್ತ್ರ",
        description: "ಹಲ್ಲು/ಒಸಡುಗಳ ಮೇಲೆ ಗಟ್ಟಿಯಾದ ಹಳದಿ-ಬಿಳಿ ನಿಕ್ಷೇಪಗಳು",
        treatment: "ಡೀಪ್ ಸ್ಕೇಲಿಂಗ್",
        price: "₹ 150",
      },
      marathi: {
        name: "टार्टर",
        description: "दात/हिरड्यांवर कडक पिवळसर-पांढरा साठा",
        treatment: "संपूर्ण स्वच्छता आणि पॉलिशिंग",
        price: "₹ 150",
      },
      hindi: {
        name: "टार्टर",
        description: "दांतों/मसूड़ों पर सख्त पीला-सफेद जमाव",
        treatment: "गहराई से सफाई",
        price: "₹ 150",
      },
      tamil: {
        name: "கால்குலஸ்",
        description: "பற்கள்/ஈறுகளில் கடினமான மஞ்சள்-வெள்ளை படிவுகள்",
        treatment: "ஆழமான அளவிடுதல்",
        price: "₹ 150",
      },
      telugu: {
        name: "కాలిక్యులస్",
        description: "దంతాలు/చిగుళ్లపై గట్టి పసుపు-తెలుపు నిక్షేపాలు",
        treatment: "డీప్ స్కేలింగ్",
        price: "₹ 150",
      },
      gujarati: {
        name: "નળી",
        description: "દાંત/પે ums ા પર સખત પીળી-સફેદ થાપણો",
        treatment: "Deepંડે સ્કેલિંગ",
        price: "₹ 150",
      },
    },
    "dental fluorosis": {
      color: "#FF9A8C",
      english: {
        name: "Fluorosis",
        description: "Whitish brown spots due to excess fluoride",
        treatment: "Veneer/ Cap",
        price: "₹ 920",
      },
      kannada: {
        name: "ಫ್ಲೋರೋಸಿಸ್",
        description: "ಹೆಚ್ಚುವರಿ ಫ್ಲೋರೈಡ್‌ನಿಂದಾಗಿ ಬಿಳಿ ಕಂದು ಕಲೆಗಳು",
        treatment: "ವೆನಿರ್ / ಕ್ಯಾಪ್",
        price: "₹ 920",
      },
      marathi: {
        name: "फ्लोरोसिस",
        description: "फ्लोराईड जास्त असल्यामुळे झालेले पांढरे तपकिरी डाग",
        treatment: "विनियर / कैप",
        price: "₹ 920",
      },
      hindi: {
        name: "फ्लोरोसिस",
        description: "अत्यधिक फ्लोराइड के कारण सफेद भूरे रंग के धब्बे",
        treatment: "विनियर / कैप",
        price: "₹ 920",
      },
      tamil: {
        name: "ஃப்ளோரோசிஸ்",
        description:
          "அதிகப்படியான புளோரைடு காரணமாக வெண்மை கலந்த பழுப்பு நிற புள்ளிகள்",
        treatment: "வெனீர் / தொப்பி",
        price: "₹ 920",
      },
      telugu: {
        name: "ఫ్లోరోసిస్",
        description: "అధిక ఫ్లోరైడ్ కారణంగా తెల్లటి గోధుమ రంగు మచ్చలు",
        treatment: "వెనీర్ / క్యాప్",
        price: "₹ 920",
      },
      gujarati: {
        name: "ફ્લોરોસિસ",
        description: "વધુ ફ્લોરાઇડને કારણે સફેદ ભુરો ફોલ્લીઓ",
        treatment: "બેશરમ",
        price: "₹ 920",
      },
    },
    "missing tooth": {
      color: "#4B8378",
      english: {
        name: "Missing tooth",
        description: "A gap due to missing tooth",
        treatment: "Implant/ Bridge/ Denture",
        price: "₹ 10000 / 900 / 200",
      },
      kannada: {
        name: "ಹಲ್ಲು ಕಾಣೆಯಾಗಿದೆ",
        description: "ಕಾಣೆಯಾದ ಹಲ್ಲಿನ ಕಾರಣ ಅಂತರ",
        treatment: "ಇಂಪ್ಲಾಂಟ್ / ಸೇತುವೆ / ದಂತದ್ರವ್ಯ",
        price: "₹ 10000 / 900 / 200",
      },
      marathi: {
        name: "गहाळ दात",
        description: "दात नसल्यामुळे अंतर",
        treatment: "इम्प्लांट / ब्रिज / कवळी",
        price: "₹ 10000 / 900 / 200",
      },
      hindi: {
        name: "दांत में गॅप",
        description: "दांत न होने के कारण गैप",
        treatment: "इम्प्लांट / ब्रिज / डेंचर",
        price: "₹ 10000 / 900 / 200",
      },
      tamil: {
        name: "பல் காணவில்லை",
        description: "பல் இல்லாததால் ஒரு இடைவெளி",
        treatment: "உள்வைப்பு / பாலம் / பல்வகை",
        price: "₹ 10000 / 900 / 200",
      },
      telugu: {
        name: "పంటి లేదు",
        description: "పంటి తప్పిపోయిన కారణంగా గ్యాప్",
        treatment: "ఇంప్లాంట్/ బ్రిడ్జ్/ డెంచర్",
        price: "₹ 10000 / 900 / 200",
      },
      gujarati: {
        name: "દાંત ખૂટે છે",
        description: "ગુમ થયેલ દાંતને કારણે અંતર",
        treatment: "રોપવું/ પુલ/ દાંત",
        price: "₹ 10000 / 900 / 200",
      },
    },
    fracture: {
      color: "#808080",
      english: {
        name: "Fracture",
        description: "Semi or fully broken tooth",
        treatment: "Filling/ Cap",
        price: "₹ 200 / 920",
      },
      kannada: {
        name: "ಮುರಿತ",
        description: "ಅರೆ ಅಥವಾ ಸಂಪೂರ್ಣವಾಗಿ ಮುರಿದ ಹಲ್ಲು",
        treatment: "ಭರ್ತಿ / ಕ್ಯಾಪ್",
        price: "₹ 200 / 920",
      },
      marathi: {
        name: "तुटलेला दात",
        description: "अर्धा किंवा पूर्ण तुटलेला दात",
        treatment: "फिलिंग / कॅप",
        price: "₹ 200 / 920",
      },
      hindi: {
        name: "टूटा हुआ दांत",
        description: "आधा या पूरी तरह से टूटा हुआ दांत",
        treatment: "फिलिंग / कैप",
        price: "₹ 200 / 920",
      },
      tamil: {
        name: "எலும்பு முறிவு",
        description: "அரை அல்லது முழுமையாக உடைந்த பல்",
        treatment: "நிரப்புதல் / தொப்பி",
        price: "₹ 200 / 920",
      },
      telugu: {
        name: "ఫ్రాక్చర్",
        description: "సెమీ లేదా పూర్తిగా విరిగిన దంతాలు",
        treatment: "ఫిల్లింగ్ / క్యాప్",
        price: "₹ 200 / 920",
      },
      gujarati: {
        name: "અસ્થિભંગ",
        description: "અર્ધ અથવા સંપૂર્ણપણે તૂટેલા દાંત",
        treatment: "ભરણ/ ટોપી",
        price: "₹ 200 / 920",
      },
    },
    "root stump": {
      color: "#FF00FF",
      english: {
        name: "Root stump",
        description: "Pieces of root/tooth remaining",
        treatment: "Extraction",
        price: "₹ 40",
      },
      kannada: {
        name: "ರೂಟ್ ಸ್ಟಂಪ್",
        description: "ಬೇರು/ಹಲ್ಲಿನ ತುಂಡುಗಳು ಉಳಿದಿವೆ",
        treatment: "ಹೊರತೆಗೆಯುವಿಕೆ",
        price: "₹ 40",
      },
      marathi: {
        name: "रूट स्टंप",
        description: "कीड खोल असल्यामुळे फक्त दातांचे मूळ वाचले आहे",
        treatment: "दात काढणे",
        price: "₹ 40",
      },
      hindi: {
        name: "रूट स्टंप",
        description: "जड़/दाँत के बचे हुए टुकड़े",
        treatment: "दांत निकालना",
        price: "₹ 40",
      },
      tamil: {
        name: "ரூட் ஸ்டம்ப்",
        description: "மீதமுள்ள வேர்/பல் துண்டுகள்",
        treatment: "பிரித்தெடுத்தல்",
        price: "₹ 40",
      },
      telugu: {
        name: "రూట్ స్టంప్",
        description: "రూట్/టూత్ ముక్కలు మిగిలి ఉన్నాయి",
        treatment: "వెలికితీత",
        price: "₹ 40",
      },
      gujarati: {
        name: "મૂળિયા",
        description: "મૂળ/દાંતના ટુકડાઓ બાકી",
        treatment: "નિષ્કર્ષણ",
        price: "₹ 40",
      },
    },
    "grossly decayed": {
      color: "#FB6090",
      english: {
        name: "Grossly decayed tooth",
        description: "Minimum tooth structure left due to deep cavity",
        treatment: "Extraction",
        price: "₹ 40",
      },
      kannada: {
        name: "ತೀವ್ರವಾಗಿ ಕೊಳೆತ ಹಲ್ಲು",
        description: "ಆಳವಾದ ಕುಳಿಯಿಂದಾಗಿ ಕನಿಷ್ಠ ಹಲ್ಲಿನ ರಚನೆ ಉಳಿದಿದೆ",
        treatment: "ಹೊರತೆಗೆಯುವಿಕೆ",
        price: "₹ 40",
      },
      marathi: {
        name: "पूर्ण किडलेला दात",
        description: "कीड खोल गेल्यामुळे दातांचा लहान भाग शिल्लक आहे",
        treatment: "दात काढणे",
        price: "₹ 40",
      },
      hindi: {
        name: "पूरा खराब दांत",
        description:
          "गहरा कीड़ा होने के कारण दांत का केवल एक छोटा सा हिस्सा ही बचा है",
        treatment: "दांत निकालना",
        price: "₹ 40",
      },
      tamil: {
        name: "மொத்தமாக சிதைந்த பல்",
        description:
          "ஆழமான குழியின் காரணமாக குறைந்தபட்ச பல் அமைப்பு எஞ்சியிருக்கிறது",
        treatment: "பிரித்தெடுத்தல்",
        price: "₹ 40",
      },
      telugu: {
        name: "ఘోరంగా క్షీణించిన దంతాలు",
        description: "లోతైన కుహరం కారణంగా మిగిలి ఉన్న కనీస దంతాల నిర్మాణం",
        treatment: "వెలికితీత",
        price: "₹ 40",
      },
      gujarati: {
        name: "એકદમ ક્ષીણ દાંત",
        description: "Deep ંડા પોલાણને કારણે ઓછામાં ઓછું દાંતનું માળખું",
        treatment: "નિષ્કર્ષણ",
        price: "₹ 40",
      },
    },
    malaligned: {
      color: "#FFFF00",
      english: {
        name: "Malaligned",
        description: "Crooked teeth, not in alignment",
        treatment: "Braces/ Clear Aligner",
        price: "₹ 15000",
      },
      kannada: {
        name: "ಅಸಮರ್ಪಕ",
        description: "ವಕ್ರ ಹಲ್ಲುಗಳು, ಜೋಡಣೆಯಲ್ಲ",
        treatment: "ಕಟ್ಟುಪಟ್ಟಿಗಳು / ಅಲೈನರ್ ತೆರವುಗೊಳಿಸಿ",
        price: "₹ 15000",
      },
      marathi: {
        name: "वेडे-वाकडे दात",
        description: "दात संरेखित नाहीत",
        treatment: "ब्रेसेस / क्लिअर अलाइनर",
        price: "₹ 15000",
      },
      hindi: {
        name: "टेढे दांत",
        description: "टेढे मेढे दांत",
        treatment: "ब्रेसेस / क्लियर एलाइनर्स",
        price: "₹ 15000",
      },
      tamil: {
        name: "தவறானது",
        description: "வளைந்த பற்கள், சீரமைப்பில் இல்லை",
        treatment: "பிரேஸ்கள் / தெளிவான சீரமைப்பி",
        price: "₹ 15000",
      },
      telugu: {
        name: "మాలాలైన్డ్",
        description: "వంకర పళ్ళు, సమలేఖనంలో లేవు",
        treatment: "కలుపులు/ క్లియర్ అలైనర్",
        price: "₹ 15000",
      },
      gujarati: {
        name: "નબળા",
        description: "કુટિલ દાંત, ગોઠવણીમાં નહીં",
        treatment: "કૌંસ/ સ્પષ્ટ ગોઠવણી કરનાર",
        price: "₹ 15000",
      },
    },
    attrition: {
      color: "#00FFFF",
      english: {
        name: "Attrition",
        description: "Worn off tooth on biting surface",
        treatment: "Filling/ Night Guard",
        price: "₹ 200 / 920",
      },
      kannada: {
        name: "ತಿಕ್ಕಾಟ",
        description: "ಕಚ್ಚುವ ಮೇಲ್ಮೈಯಲ್ಲಿ ಹಲ್ಲು ಉದುರಿಹೋಗಿದೆ",
        treatment: "ಭರ್ತಿ / ರಾತ್ರಿ ಗಾರ್ಡ್",
        price: "₹ 200 / 920",
      },
      marathi: {
        name: "दातांची झीज झाली आहे",
        description: "दातांची अत्याधिक झीज झाल्याने ठणक लागू शकते",
        treatment: "फिलिंग / कॅप / नाईट गार्ड",
        price: "₹ 200 / 920",
      },
      hindi: {
        name: "दंत संघर्षण",
        description: "घिसा हुआ दांत",
        treatment: "फिलिंग / नाइट गार्ड",
        price: "₹ 200 / 920",
      },
      tamil: {
        name: "தேய்வு",
        description: "கடிக்கும் மேற்பரப்பில் பல் தேய்ந்தது",
        treatment: "நிரப்புதல் / இரவு காவலர்",
        price: "₹ 200 / 920",
      },
      telugu: {
        name: "అట్రిషన్",
        description: "కొరికే ఉపరితలంపై పంటి అరిగిపోయింది",
        treatment: "ఫిల్లింగ్/ నైట్ గార్డ్",
        price: "₹ 200 / 920",
      },
      gujarati: {
        name: "આડો",
        description: "ડંખ મારતી સપાટી પર દાંત પહેરવામાં આવે છે",
        treatment: "ભરણ/ નાઇટ ગાર્ડ",
        price: "₹ 200 / 920",
      },
    },
    abfraction: {
      color: "#F9B208",
      english: {
        name: "Abfraction",
        description: "V-shaped defect on the tooth near gum",
        treatment: "Filling",
        price: "₹ 200",
      },
      kannada: {
        name: "ಅಮೂರ್ತತೆ",
        description: "ಗಮ್ ಬಳಿ ಹಲ್ಲಿನ ಮೇಲೆ ವಿ-ಆಕಾರದ ದೋಷ",
        treatment: "ತುಂಬಿಸುವ",
        price: "₹ 200",
      },
      marathi: {
        name: "अब्रेक्शन",
        description: "हिरड्याजवळच्या दातावर V आकाराचा दोष",
        treatment: "फिलिंग",
        price: "₹ 200",
      },
      hindi: {
        name: "अब्रेक्शन",
        description: "वी के आकार का दांत पर घिस जाना",
        treatment: "फिलिंग",
        price: "₹ 200",
      },
      tamil: {
        name: "சுருக்கம்",
        description: "ஈறுக்கு அருகில் உள்ள பல்லில் V வடிவ குறைபாடு",
        treatment: "நிரப்புதல்",
        price: "₹ 200",
      },
      telugu: {
        name: "విక్షేపం",
        description: "చిగుళ్ల దగ్గర పంటిపై V- ఆకారపు లోపం",
        treatment: "నింపడం",
        price: "₹ 200",
      },
      gujarati: {
        name: "પૂરું",
        description: "ગમ નજીક દાંત પર વી આકારની ખામી",
        treatment: "ભરવા",
        price: "₹ 200",
      },
    },
    diastema: {
      color: "#7c6e61",
      english: {
        name: "Diastema",
        description: "A gap between upper front two teeth",
        treatment: "Filling / Veneer",
        price: "₹ 200 / 920",
      },
      kannada: {
        name: "ಡಯಾಸ್ಟೆಮಾ",
        description: "ಮೇಲಿನ ಮುಂಭಾಗದ ಎರಡು ಹಲ್ಲುಗಳ ನಡುವಿನ ಅಂತರ",
        treatment: "ಭರ್ತಿ / ವೆನಿರ್",
        price: "₹ 200 / 920",
      },
      marathi: {
        name: "डायस्टेमा",
        description: "वरच्या दोन दातांमधील अंतर",
        treatment: "विनियर / फिलिंग",
        price: "₹ 200 / 920",
      },
      hindi: {
        name: "सामने के दांतों के बीच फांक",
        description: "सामने के दांतों के बीच फांक",
        treatment: "विनियर / फिलिंग",
        price: "₹ 200 / 920",
      },
      tamil: {
        name: "டயஸ்டெமா",
        description: "மேல் முன் இரண்டு பற்களுக்கு இடையே ஒரு இடைவெளி",
        treatment: "நிரப்புதல் / வெனீர்",
        price: "₹ 200 / 920",
      },
      telugu: {
        name: "డయాస్టెమా",
        description: "ఎగువ ముందు రెండు దంతాల మధ్య అంతరం",
        treatment: "ఫిల్లింగ్ / వెనీర్",
        price: "₹ 200 / 920",
      },
      gujarati: {
        name: "ત્રાસ",
        description: "ઉપલા આગળના બે દાંત વચ્ચેનો અંતર",
        treatment: "ભરણ / જાદુઈ",
        price: "₹ 200 / 920",
      },
    },
    "tooth gap": {
      color: "#3B0918",
      english: {
        name: "Tooth gap",
        description: "A gap between two teeth",
        treatment: "Orthodontic treatment",
        price: "₹ 15000",
      },
      kannada: {
        name: "ಹಲ್ಲಿನ ಅಂತರ",
        description: "ಎರಡು ಹಲ್ಲುಗಳ ನಡುವೆ ಅಂತರ",
        treatment: "ಆರ್ಥೊಡಾಂಟಿಕ್ ಚಿಕಿತ್ಸೆ",
        price: "₹ 15000",
      },
      marathi: {
        name: "दोन दातांमधील फट",
        description: "दातांमधील अंतर",
        treatment: "ब्रेसेस / क्लिअर अलाइनर",
        price: "₹ 15000",
      },
      hindi: {
        name: "दो दांतों के बीच फांक",
        description: "दो दांतों के बीच फांक",
        treatment: "दांतो को सीधा करने की इलाज",
        price: "₹ 15000",
      },
      tamil: {
        name: "பல் இடைவெளி",
        description: "இரண்டு பற்களுக்கு இடையே ஒரு இடைவெளி",
        treatment: "ஆர்த்தோடோன்டிக் சிகிச்சை",
        price: "₹ 15000",
      },
      telugu: {
        name: "పంటి గ్యాప్",
        description: "రెండు దంతాల మధ్య అంతరం",
        treatment: "ఆర్థోడోంటిక్ చికిత్స",
        price: "₹ 15000",
      },
      gujarati: {
        name: "દાંતનું અંતર",
        description: "બે દાંત વચ્ચેનો અંતર",
        treatment: "રૂthિગત સારવાર",
        price: "₹ 15000",
      },
    },
    "silver filling": {
      color: "#73777B",
      english: {
        name: "Silver filling",
        description: "Black color filling which can be damaging in long run",
        treatment: "Replace with safe tooth colored filling",
        price: "₹ 200 - 350",
      },
      kannada: {
        name: "ಬೆಳ್ಳಿ ತುಂಬುವುದು",
        description: "ಕಪ್ಪು ಬಣ್ಣ ತುಂಬುವಿಕೆಯು ದೀರ್ಘಾವಧಿಯಲ್ಲಿ ಹಾನಿಗೊಳಗಾಗಬಹುದು",
        treatment: "ಸುರಕ್ಷಿತ ಹಲ್ಲಿನ ಬಣ್ಣದ ತುಂಬುವಿಕೆಯೊಂದಿಗೆ ಬದಲಾಯಿಸಿ",
        price: "₹ 200 - 350",
      },
      marathi: {
        name: "चांदी भरलेला दात",
        description: "चांदी मध्ये असलेला पारा हानिकारक आहे",
        treatment: "टूथ कलर फिलिंग्सने बदलून घ्या",
        price: "₹ 200 - 350",
      },
      hindi: {
        name: "सिल्वर फिलिंग",
        description: "चांदी/काले रंग का दाँत फिलिंग",
        treatment: "सुरक्षित दांत के रंग के फिलिंग से बदलें",
        price: "₹ 200 - 350",
      },
      tamil: {
        name: "வெள்ளி நிரப்புதல்",
        description: "கருப்பு நிற நிரப்புதல் நீண்ட காலத்திற்கு சேதமடையலாம்",
        treatment: "பாதுகாப்பான பல் நிற நிரப்புதலுடன் மாற்றவும்",
        price: "₹ 200 - 350",
      },
      telugu: {
        name: "వెండి నింపడం",
        description: "బ్లాక్ కలర్ ఫిల్లింగ్ దీర్ఘకాలంలో దెబ్బతింటుంది",
        treatment: "సురక్షితమైన టూత్ కలర్ ఫిల్లింగ్తో భర్తీ చేయండి",
        price: "₹ 200 - 350",
      },
      gujarati: {
        name: "ચાંદી ભરવા",
        description: "બ્લેક કલર ભરવાનું જે લાંબા ગાળે નુકસાનકારક હોઈ શકે છે",
        treatment: "સલામત દાંતના રંગીન ભરવા સાથે બદલો",
        price: "₹ 200 - 350",
      },
    },
    "composite filling": {
      color: "#C85C5C",
      english: {
        name: "Composite filling",
        description: "Tooth colored filling",
        treatment: "Replace if broken",
        price: "₹ 350",
      },
      kannada: {
        name: "ಸಂಯೋಜಿತ ಭರ್ತಿ",
        description: "ಹಲ್ಲಿನ ಬಣ್ಣ ತುಂಬುವುದು",
        treatment: "ಮುರಿದರೆ ಬದಲಾಯಿಸಿ",
        price: "₹ 350",
      },
      marathi: {
        name: "कम्पोजिट फीलिंग",
        description: "दातासमान रंग असणारी फिलिंग",
        treatment: "तुटल्यास बदला",
        price: "₹ 350",
      },
      hindi: {
        name: "कम्पोजिट फीलिंग",
        description: "दांतों के रंग का भराव",
        treatment: "अगर टूटा हुआ है तो बदलें",
        price: "₹ 350",
      },
      tamil: {
        name: "கலப்பு நிரப்புதல்",
        description: "பல் நிற நிரப்புதல்",
        treatment: "உடைந்தால் மாற்றவும்",
        price: "₹ 350",
      },
      telugu: {
        name: "మిశ్రమ పూరకం",
        description: "పంటి రంగు నింపడం",
        treatment: "విచ్ఛిన్నమైతే భర్తీ చేయండి",
        price: "₹ 350",
      },
      gujarati: {
        name: "સંયુક્ત ભરણ",
        description: "દાંતનો રંગ ભરવો",
        treatment: "જો તૂટે તો બદલો",
        price: "₹ 350",
      },
    },
    "metal crown": {
      color: "#FF0075",
      english: {
        name: "Metal crown",
        description: "Black metal cap",
        treatment: "Keep it under observation",
        price: "FREE scan on DentalDost",
      },
      kannada: {
        name: "ಲೋಹದ ಕಿರೀಟ",
        description: "ಕಪ್ಪು ಲೋಹದ ಕ್ಯಾಪ್",
        treatment: "ಅದನ್ನು ವೀಕ್ಷಣೆಯಲ್ಲಿ ಇರಿಸಿ",
        price: "DentalDost ನಲ್ಲಿ ಉಚಿತ ಸ್ಕ್ಯಾನ್",
      },
      marathi: {
        name: "ब्लॅक मेटल कॅप",
        description: "काळ्या धातूचा कॅप",
        treatment: "निरीक्षणाखाली ठेवा",
        price: "डेंटलडॉस्टवर विनामूल्य स्कॅन",
      },
      hindi: {
        name: "मेटल कैप",
        description: "काली धातु के कैप",
        treatment: "निगरानी में रखें",
        price: "डेंटलडॉस्ट पर मुफ्त स्कैन",
      },
      tamil: {
        name: "உலோக கிரீடம்",
        description: "கருப்பு உலோக தொப்பி",
        treatment: "அதை கண்காணிப்பில் வைக்கவும்",
        price: "DentalDost இல் இலவச ஸ்கேன்",
      },
      telugu: {
        name: "మెటల్ కిరీటం",
        description: "బ్లాక్ మెటల్ టోపీ",
        treatment: "పరిశీలనలో ఉంచండి",
        price: "DentalDostలో ఉచిత స్కాన్",
      },
      gujarati: {
        name: "ધાતુનો તાજ",
        description: "કાળી ધાતુ",
        treatment: "તેને નિરીક્ષણ હેઠળ રાખો",
        price: "FREE scan on DentalDost",
      },
    },
    "ceramic crown": {
      color: "#77D970",
      english: {
        name: "Ceramic crown",
        description: "Tooth coloured cap",
        treatment: "Keep it under observation",
        price: "FREE scan on DentalDost",
      },
      kannada: {
        name: "ಸೆರಾಮಿಕ್ ಕಿರೀಟ",
        description: "ಹಲ್ಲಿನ ಬಣ್ಣದ ಕ್ಯಾಪ್",
        treatment: "ಅದನ್ನು ವೀಕ್ಷಣೆಯಲ್ಲಿ ಇರಿಸಿ",
        price: "DentalDost ನಲ್ಲಿ ಉಚಿತ ಸ್ಕ್ಯಾನ್",
      },
      marathi: {
        name: "सिरेमिक कॅप",
        description: "दातासमान रंग असणारी कॅप",
        treatment: "निरीक्षणाखाली ठेवा",
        price: "डेंटलडॉस्टवर विनामूल्य स्कॅन",
      },
      hindi: {
        name: "सिरेमिक कैप",
        description: "दांत के रंग का कैप",
        treatment: "निगरानी में रखें",
        price: "डेंटलडॉस्ट पर मुफ्त स्कैन",
      },
      tamil: {
        name: "பீங்கான் கிரீடம்",
        description: "பல் நிற தொப்பி",
        treatment: "அதை கண்காணிப்பில் வைக்கவும்",
        price: "DentalDost இல் இலவச ஸ்கேன்",
      },
      telugu: {
        name: "సిరామిక్ కిరీటం",
        description: "పంటి రంగు టోపీ",
        treatment: "పరిశీలనలో ఉంచండి",
        price: "DentalDostలో ఉచిత స్కాన్",
      },
      gujarati: {
        name: "કળણનો તાજ",
        description: "દાંત",
        treatment: "તેને નિરીક્ષણ હેઠળ રાખો",
        price: "FREE scan on DentalDost",
      },
    },
    abrasion: {
      color: "#774360",
      english: {
        name: "Abrasion",
        description: "Flattening of front surface of the tooth",
        treatment: "Tooth colour filling",
        price: "₹ 350",
      },
      kannada: {
        name: "ಸವೆತ",
        description: "ಹಲ್ಲಿನ ಮುಂಭಾಗದ ಮೇಲ್ಮೈಯನ್ನು ಚಪ್ಪಟೆಗೊಳಿಸುವುದು",
        treatment: "ಹಲ್ಲಿನ ಬಣ್ಣ ತುಂಬುವುದು",
        price: "₹ 350",
      },
      marathi: {
        name: "दातांचे घर्षण",
        description: "दातांच्या समोरील भागाचे घर्षण",
        treatment: "दातासमान फिलिंग",
        price: "₹ 350",
      },
      hindi: {
        name: "दांतों का घर्षण",
        description: "दांतों के सामने की सतह का चपटा होना",
        treatment: "दांतो के रंग के फिलिंग",
        price: "₹ 350",
      },
      tamil: {
        name: "சிராய்ப்பு",
        description: "பல்லின் முன் மேற்பரப்பைத் தட்டையாக்குதல்",
        treatment: "பல் நிறத்தை நிரப்புதல்",
        price: "₹ 350",
      },
      telugu: {
        name: "రాపిడి",
        description: "దంతాల ముందు ఉపరితలం చదును చేయడం",
        treatment: "పంటి రంగు నింపడం",
        price: "₹ 350",
      },
      gujarati: {
        name: "ઘર્ષણ",
        description: "દાંતની આગળની સપાટીની ચપટી",
        treatment: "દાંતનો રંગ ભરવો",
        price: "₹ 350",
      },
    },
    denture: {
      color: "#A64B2A",
      english: {
        name: "Denture",
        description: "Artificial set of teeth",
        treatment: "Maintain hygiene",
        price: "FREE scan on DentalDost",
      },
      kannada: {
        name: "ದಂತಪಂಕ್ತಿ",
        description: "ಹಲ್ಲುಗಳ ಕೃತಕ ಸೆಟ್",
        treatment: "ನೈರ್ಮಲ್ಯವನ್ನು ಕಾಪಾಡಿಕೊಳ್ಳಿ",
        price: "DentalDost ನಲ್ಲಿ ಉಚಿತ ಸ್ಕ್ಯಾನ್",
      },
      marathi: {
        name: "कवळी",
        description: "खोट्या दातांनी बनवलेले कवळी",
        treatment: "स्वच्छता राखणे",
        price: "डेंटलडॉस्टवर विनामूल्य स्कॅन",
      },
      hindi: {
        name: "नकली दांत",
        description: "कृत्रिम दांतों की पंक्ति",
        treatment: "स्वच्छता बनाए रखें",
        price: "डेंटलडॉस्ट पर मुफ्त स्कैन",
      },
      tamil: {
        name: "பல்வகை",
        description: "செயற்கை பற்களின் தொகுப்பு",
        treatment: "சுகாதாரத்தை பேணுங்கள்",
        price: "DentalDost இல் இலவச ஸ்கேன்",
      },
      telugu: {
        name: "కట్టుడు పళ్ళు",
        description: "దంతాల కృత్రిమ సెట్",
        treatment: "పరిశుభ్రత పాటించండి",
        price: "DentalDostలో ఉచిత స్కాన్",
      },
      gujarati: {
        name: "ડેન્ચર",
        description: "કૃત્રિમ દાંતનો સમૂહ",
        treatment: "સ્વચ્છતા જાળવી",
        price: "FREE scan on DentalDost",
      },
    },
    appliances: {
      color: "#B25068",
      english: {
        name: "Appliances",
        description: "Post ortho treatment retainer",
        treatment: "Maintain hygiene",
        price: "FREE scan on DentalDost",
      },
      kannada: {
        name: "ಉಪಕರಣಗಳು",
        description: "ಪೋಸ್ಟ್ ಆರ್ಥೋ ಚಿಕಿತ್ಸೆಯ ಧಾರಕ",
        treatment: "ನೈರ್ಮಲ್ಯವನ್ನು ಕಾಪಾಡಿಕೊಳ್ಳಿ",
        price: "DentalDost ನಲ್ಲಿ ಉಚಿತ ಸ್ಕ್ಯಾನ್",
      },
      marathi: {
        name: "उपकरण",
        description: "ऑर्थो ट्रीटमेंट झाल्यानंतर असलेले उपकरण",
        treatment: "स्वच्छता राखणे",
        price: "डेंटलडॉस्टवर विनामूल्य स्कॅन",
      },
      hindi: {
        name: "उपकरण",
        description: "ऑर्थो ट्रीटमेंट के बाद रिटेनर",
        treatment: "स्वच्छता बनाए रखें",
        price: "डेंटलडॉस्ट पर मुफ्त स्कैन",
      },
      tamil: {
        name: "உபகரணங்கள்",
        description: "பிந்தைய ஆர்த்தோ சிகிச்சை தக்கவைப்பவர்",
        treatment: "சுகாதாரத்தை பேணுங்கள்",
        price: "DentalDost இல் இலவச ஸ்கேன்",
      },
      telugu: {
        name: "గృహోపకరణాలు",
        description: "పోస్ట్ ఆర్థో ట్రీట్మెంట్ రిటైనర్",
        treatment: "పరిశుభ్రత పాటించండి",
        price: "DentalDostలో ఉచిత స్కాన్",
      },
      gujarati: {
        name: "અરજીઓ",
        description: "ઓર્થો ટ્રીટમેન્ટ રીટેનર પોસ્ટ",
        treatment: "સ્વચ્છતા જાળવી",
        price: "FREE scan on DentalDost",
      },
    },
    "metal ceramic": {
      color: "#DACC96",
      english: {
        name: "Metal ceramic crown",
        description: "Tooth colored cap with metal lining",
        treatment: "Keep it under observation",
        price: "FREE scan on DentalDost",
      },
      kannada: {
        name: "ಲೋಹದ ಸೆರಾಮಿಕ್ ಕಿರೀಟ",
        description: "ಲೋಹದ ಒಳಪದರದೊಂದಿಗೆ ಹಲ್ಲಿನ ಬಣ್ಣದ ಕ್ಯಾಪ್",
        treatment: "ಅದನ್ನು ವೀಕ್ಷಣೆಯಲ್ಲಿ ಇರಿಸಿ",
        price: "DentalDost ನಲ್ಲಿ ಉಚಿತ ಸ್ಕ್ಯಾನ್",
      },
      marathi: {
        name: "मेटल सिरेमिक कॅप",
        description: "धातूच्या अस्तरांसह दात रंगीत कॅप",
        treatment: "निरीक्षणाखाली ठेवा",
        price: "डेंटलडॉस्टवर विनामूल्य स्कॅन",
      },
      hindi: {
        name: "मेटल सिरेमिक कैप",
        description: "चाँदी लाइनिंग के साथ दाँत कलर कैप",
        treatment: "निगरानी में रखें",
        price: "डेंटलडॉस्ट पर मुफ्त स्कैन",
      },
      tamil: {
        name: "உலோக பீங்கான் கிரீடம்",
        description: "உலோகப் புறணியுடன் கூடிய பல் நிற தொப்பி",
        treatment: "அதை கண்காணிப்பில் வைக்கவும்",
        price: "DentalDost இல் இலவச ஸ்கேன்",
      },
      telugu: {
        name: "మెటల్ సిరామిక్ కిరీటం",
        description: "మెటల్ లైనింగ్‌తో టూత్ కలర్ క్యాప్",
        treatment: "పరిశీలనలో ఉంచండి",
        price: "DentalDostలో ఉచిత స్కాన్",
      },
      gujarati: {
        name: "ધાતુના સિરામિક તાજ",
        description: "મેટલ અસ્તર સાથે દાંત રંગની કેપ",
        treatment: "તેને નિરીક્ષણ હેઠળ રાખો",
        price: "FREE scan on DentalDost",
      },
    },
    "impacted 3rd molar / pericoronitis": {
      color: "#6D8B74",
      english: {
        name: "Impacted third molar",
        description: "Partially visible or not visible wisdom tooth",
        treatment: "Extraction",
        price: "₹ 100 - 500",
      },
      kannada: {
        name: "ಪ್ರಭಾವಿತ ಮೂರನೇ ಮೋಲಾರ್",
        description: "ಭಾಗಶಃ ಗೋಚರಿಸುವ ಅಥವಾ ಗೋಚರಿಸದ ಬುದ್ಧಿವಂತಿಕೆಯ ಹಲ್ಲು",
        treatment: "ಹೊರತೆಗೆಯುವಿಕೆ",
        price: "₹ 100 - 500",
      },
      marathi: {
        name: "अक्कल दाढ",
        description: "अक्कल दाढ, अर्धी किंवा पुर्णपणे जबड्या मध्ये रूतलेली आहे",
        treatment: "अक्कल दाढ काढावी लागेल",
        price: "₹ 100 - 500",
      },
      hindi: {
        name: "अक्ल दाढ़",
        description:
          "आंशिक रूप से दिखाई देने वाला या न दिखाई देने वाला अकल दाढ़",
        treatment: "दांत निकालना",
        price: "₹ 100 - 500",
      },
      tamil: {
        name: "பாதிக்கப்பட்ட மூன்றாவது மோலார்",
        description: "ஓரளவு தெரியும் அல்லது தெரியாத ஞானப் பல்",
        treatment: "பிரித்தெடுத்தல்",
        price: "₹ 100 - 500",
      },
      telugu: {
        name: "మూడవ మోలార్ ప్రభావితమైంది",
        description: "పాక్షికంగా కనిపించే లేదా కనిపించని జ్ఞాన దంతాలు",
        treatment: "వెలికితీత",
        price: "₹ 100 - 500",
      },
      gujarati: {
        name: "અસરગ્રસ્ત ત્રીજી દા ol",
        description: "આંશિક રૂપે દૃશ્યમાન અથવા દૃશ્યમાન શાણપણ દાંત",
        treatment: "નિષ્કર્ષણ",
        price: "₹ 100 - 500",
      },
    },
    "discolored tooth": {
      color: "#F9CEEE",
      english: {
        name: "Discoloured tooth",
        description: "Changed tooth color than normal",
        treatment: "Root canal treatment",
        price: "₹ 990+",
      },
      kannada: {
        name: "ಬಣ್ಣಬಣ್ಣದ ಹಲ್ಲು",
        description: "ಹಲ್ಲಿನ ಬಣ್ಣವನ್ನು ಸಾಮಾನ್ಯಕ್ಕಿಂತ ಬದಲಾಯಿಸಲಾಗಿದೆ",
        treatment: "ಮೂಲ ಕಾಲುವೆ ಚಿಕಿತ್ಸೆ",
        price: "₹ 990+",
      },
      marathi: {
        name: "रंग बदललेला दात",
        description: "दातांना मार लागल्यामुळे रंग बदल झालेला दात",
        treatment: "रूट कॅनल उपचार",
        price: "₹ 990+",
      },
      hindi: {
        name: "फीका पड़ा हुआ दाँत",
        description: "बदले हुए दांतो के रंग",
        treatment: "रुट कैनाल उपचार",
        price: "₹ 990+",
      },
      tamil: {
        name: "நிறம் மாறிய பல்",
        description: "இயல்பை விட பல் நிறம் மாறிவிட்டது",
        treatment: "ரூட் கால்வாய் சிகிச்சை",
        price: "₹ 990+",
      },
      telugu: {
        name: "రంగు మారిన పంటి",
        description: "సాధారణం కంటే పంటి రంగు మార్చబడింది",
        treatment: "రూట్ కెనాల్ చికిత్స",
        price: "₹ 990+",
      },
      gujarati: {
        name: "વિકૃત દાંત",
        description: "સામાન્ય કરતાં દાંતનો રંગ બદલાયો",
        treatment: "મૂળ નહેરની સારવાર",
        price: "₹ 990+",
      },
    },
    "food lodgement": {
      color: "#ef6e29",
      english: {
        name: "Food lodgement",
        description:
          "Food is stuck in and between your teeth, can indicate tooth decay",
        treatment: "Filling",
        price: "₹ 200 - 350",
      },
      kannada: {
        name: "ಆಹಾರ ವಸತಿ",
        description:
          "ಆಹಾರವು ನಿಮ್ಮ ಹಲ್ಲುಗಳಲ್ಲಿ ಮತ್ತು ಹಲ್ಲುಗಳ ನಡುವೆ ಅಂಟಿಕೊಂಡಿರುತ್ತದೆ, ಇದು ಹಲ್ಲಿನ ಕೊಳೆತವನ್ನು ಸೂಚಿಸುತ್ತದೆ",
        treatment: "ತುಂಬಿಸುವ",
        price: "₹ 200 - 350",
      },
      marathi: {
        name: "दांतांमध्ये अन्न अडकलेले आहे",
        description: "दातामद्धे अन्न अडकत असल्यामुळे कीड असण्याची शक्यता आहे",
        treatment: "फिलिंग",
        price: "₹ 200 - 350",
      },
      hindi: {
        name: "दांतों में फंसा खाना",
        description: "खाना आपके दांतों में और उनके बीच फंसा हुआ है",
        treatment: "फिलिंग",
        price: "₹ 200 - 350",
      },
      tamil: {
        name: "உணவு தங்குமிடம்",
        description:
          "உங்கள் பற்களுக்கு இடையில் உணவு சிக்கிக்கொண்டால், அது பல் சிதைவைக் குறிக்கலாம்",
        treatment: "நிரப்புதல்",
        price: "₹ 200 - 350",
      },
      telugu: {
        name: "ఆహార వసతి",
        description:
          "ఆహారం మీ దంతాలలో మరియు వాటి మధ్య ఇరుక్కుపోయి ఉంటే, దంత క్షయాన్ని సూచిస్తుంది",
        treatment: "నింపడం",
        price: "₹ 200 - 350",
      },
      gujarati: {
        name: "ખાદ્ય -લોજમેન્ટ",
        description:
          "ખોરાક તમારા દાંતમાં અને વચ્ચે અટવાઇ જાય છે, દાંતના સડો સૂચવી શકે છે",
        treatment: "ભરવા",
        price: "₹ 200 - 350",
      },
    },
    braces: {
      color: "#F5A7A7",
      english: {
        name: "Braces",
        description: "Ongoing ortho treatment",
        treatment: "Maintain hygiene",
        price: "FREE scan on DentalDost",
      },
      kannada: {
        name: "ಕಟ್ಟುಪಟ್ಟಿಗಳು",
        description: "ನಡೆಯುತ್ತಿರುವ ಆರ್ಥೋ ಚಿಕಿತ್ಸೆ",
        treatment: "ನೈರ್ಮಲ್ಯವನ್ನು ಕಾಪಾಡಿಕೊಳ್ಳಿ",
        price: "DentalDost ನಲ್ಲಿ ಉಚಿತ ಸ್ಕ್ಯಾನ್",
      },
      marathi: {
        name: "ऑर्थो ब्रेसेस",
        description: "ऑर्थो उपचार सुरू आहे",
        treatment: "स्वच्छता राखणे",
        price: "डेंटलडॉस्टवर विनामूल्य स्कॅन",
      },
      hindi: {
        name: "दांतो में तार",
        description: "ऑर्थो का इलाज चल रहा है",
        treatment: "स्वच्छता बनाए रखें",
        price: "डेंटलडॉस्ट पर मुफ्त स्कैन",
      },
      tamil: {
        name: "பிரேஸ்கள்",
        description: "தொடர்ந்து ஆர்த்தோ சிகிச்சை",
        treatment: "சுகாதாரத்தை பேணுங்கள்",
        price: "DentalDost இல் இலவச ஸ்கேன்",
      },
      telugu: {
        name: "జంట కలుపులు",
        description: "కొనసాగుతున్న ఆర్థో చికిత్స",
        treatment: "పరిశుభ్రత పాటించండి",
        price: "DentalDostలో ఉచిత స్కాన్",
      },
      gujarati: {
        name: "કૌંસ",
        description: "ચાલુ ઓર્થો સારવાર",
        treatment: "સ્વચ્છતા જાળવી",
        price: "FREE scan on DentalDost",
      },
    },
    "receded gums": {
      color: "#AC7D88",
      english: {
        name: "Receded gums",
        description: "A part of root is visible due to gums changing position",
        treatment: "Gum Surgery",
        price: "₹ 300",
      },
      kannada: {
        name: "ಹಿಮ್ಮೆಟ್ಟಿಸಿದ ಒಸಡುಗಳು",
        description:
          "ಒಸಡುಗಳು ಸ್ಥಾನವನ್ನು ಬದಲಾಯಿಸುವುದರಿಂದ ಬೇರಿನ ಒಂದು ಭಾಗವು ಗೋಚರಿಸುತ್ತದೆ",
        treatment: "ಗಮ್ ಸರ್ಜರಿ",
        price: "₹ 300",
      },
      marathi: {
        name: "मूळ उघडे पडले आहे",
        description:
          "हिरड्यांची उंची कमी होते, लाल, सुजणे ही सुरुवातीची लक्षणे दिसतात",
        treatment: "हिरड्यांची शस्त्रक्रिया",
        price: "₹ 300",
      },
      hindi: {
        name: "दांतों की जड़ें उजागर हैं",
        description: "जड़ का एक भाग दिखाई देता है",
        treatment: "मसूड़े की सर्जरी",
        price: "₹ 300",
      },
      tamil: {
        name: "பின்வாங்கிய ஈறுகள்",
        description: "ஈறுகளின் நிலை மாறுவதால் வேரின் ஒரு பகுதி தெரியும்",
        treatment: "ஈறு அறுவை சிகிச்சை",
        price: "₹ 300",
      },
      telugu: {
        name: "చిగుళ్ళు తగ్గాయి",
        description: "చిగుళ్ల స్థానం మారడం వల్ల రూట్‌లో కొంత భాగం కనిపిస్తుంది",
        treatment: "గమ్ సర్జరీ",
        price: "₹ 300",
      },
      gujarati: {
        name: "દહન",
        description: "ગુંદર બદલવાની સ્થિતિને કારણે મૂળનો એક ભાગ દેખાય છે",
        treatment: "ગમ સર્જરી",
        price: "₹ 300",
      },
    },
    mesiodens: {
      color: "#82C4C3",
      english: {
        name: "Mesiodens",
        description: "Extra tooth present between upper two front teeth",
        treatment: "Extraction",
        price: "₹ 40",
      },
      kannada: {
        name: "ಮೆಸಿಯೊಡೆನ್ಸ್",
        description:
          "ಮೇಲಿನ ಎರಡು ಮುಂಭಾಗದ ಹಲ್ಲುಗಳ ನಡುವೆ ಹೆಚ್ಚುವರಿ ಹಲ್ಲು ಇರುತ್ತದೆ",
        treatment: "ಹೊರತೆಗೆಯುವಿಕೆ",
        price: "₹ 40",
      },
      marathi: {
        name: "अतिरिक्त दात",
        description: "हा एक अतिरिक्त दात आहे",
        treatment: "काढावी लागेल",
        price: "₹ 40",
      },
      hindi: {
        name: "अतिरिक्त दांत",
        description: "यह दांत अतिरिक्त है",
        treatment: "दांत निकालना",
        price: "₹ 40",
      },
      tamil: {
        name: "கூடுதல் பல்",
        description: "மேல் இரண்டு முன் பற்களுக்கு இடையில் கூடுதல் பல் உள்ளது",
        treatment: "பிரித்தெடுத்தல்",
        price: "₹ 40",
      },
      telugu: {
        name: "మెసియోడెన్స్",
        description: "ఎగువ రెండు ముందు దంతాల మధ్య అదనపు దంతాలు ఉన్నాయి",
        treatment: "వెలికితీత",
        price: "₹ 40",
      },
      gujarati: {
        name: "મેસિઓડન્સ",
        description: "ઉપરના બે દાંત વચ્ચે વધારાના દાંત હાજર",
        treatment: "નિષ્કર્ષણ",
        price: "₹ 40",
      },
    },
    "incomplete rct": {
      color: "#BF9742",
      english: {
        name: "Incomplete RCT",
        description:
          "Root canal treatment is not complete and very harmful for the tooth",
        treatment: "Filling & Cap",
        price: "₹ 990 - 1140",
      },
      kannada: {
        name: "ಅಪೂರ್ಣ RCT",
        description:
          "ರೂಟ್ ಕೆನಾಲ್ ಚಿಕಿತ್ಸೆಯು ಪೂರ್ಣಗೊಂಡಿಲ್ಲ ಮತ್ತು ಹಲ್ಲಿಗೆ ತುಂಬಾ ಹಾನಿಕಾರಕವಾಗಿದೆ",
        treatment: "ಭರ್ತಿ ಮತ್ತು ಕ್ಯಾಪ್",
        price: "₹ 990 - 1140",
      },
      marathi: {
        name: "अपूर्ण रूट कॅनॉल ट्रीटमेंट",
        description:
          "अपूर्ण रूट कॅनॉल झाले असल्यास दातांमध्ये पुनः संक्रमण होऊ शकतो",
        treatment: "पूर्ण रूट कॅनाल उपचार",
        price: "₹ 990 - 1140",
      },
      hindi: {
        name: "अपूर्ण रूट कैनाल उपचार",
        description: "अपूर्ण रूट कैनाल उपचार जो दांतों के लिए हानिकारक होता है",
        treatment: "फिलिंग और कैप",
        price: "₹ 990 - 1140",
      },
      tamil: {
        name: "முழுமையற்ற RCT",
        description:
          "ரூட் கால்வாய் சிகிச்சை முழுமையடையாது மற்றும் பல்லுக்கு மிகவும் தீங்கு விளைவிக்கும்",
        treatment: "நிரப்புதல் & தொப்பி",
        price: "₹ 990 - 1140",
      },
      telugu: {
        name: "అసంపూర్ణమైన RCT",
        description:
          "రూట్ కెనాల్ చికిత్స పూర్తి కాదు మరియు పంటికి చాలా హానికరం",
        treatment: "ఫిల్లింగ్ & క్యాప్",
        price: "₹ 990 - 1140",
      },
      gujarati: {
        name: "અપૂર્ણ આર.સી.ટી.",
        description:
          "રુટ કેનાલ સારવાર પૂર્ણ નથી અને દાંત માટે ખૂબ જ હાનિકારક છે",
        treatment: "ભરણ અને કેપ",
        price: "₹ 990 - 1140",
      },
    },
    "dislodged prosthesis / filling": {
      color: "#401f37",
      english: {
        name: "Dislodged prosthesis",
        description: "The filling/ prosthesis is either damaged or displaced",
        treatment: "Re-filling",
        price: "₹ 350",
      },
      kannada: {
        name: "ಡಿಸ್ಲೊಡೆಡ್ ಪ್ರೊಸ್ಥೆಸಿಸ್",
        description:
          "ಫಿಲ್ಲಿಂಗ್/ಪ್ರೊಸ್ಥೆಸಿಸ್ ಹಾನಿಗೊಳಗಾಗಿದೆ ಅಥವಾ ಸ್ಥಳಾಂತರಗೊಂಡಿದೆ",
        treatment: "ಇ-ಫೈಲಿಂಗ್",
        price: "₹ 350",
      },
      marathi: {
        name: "निघालेले फीलिन्ग/ कॅप",
        description: "फीलिंग/ कॅप/ ब्रिज खराब किंवा विस्थापित झाले आहेत",
        treatment: "रिफिलिंग",
        price: "₹ 350",
      },
      hindi: {
        name: "ढीली कैप/ फिलिंग",
        description: "विस्थापित कैप,ब्रिज या फिलिंग",
        treatment: "दुबारा भरिये",
        price: "₹ 350",
      },
      tamil: {
        name: "சிதைந்த செயற்கை உறுப்பு",
        description:
          "நிரப்புதல்/புரோஸ்டெசிஸ் சேதமடைந்துள்ளது அல்லது இடம்பெயர்ந்துள்ளது",
        treatment: "மின்-தாக்கல்",
        price: "₹ 350",
      },
      telugu: {
        name: "డిస్లోజ్డ్ ప్రొస్థెసిస్",
        description:
          "ఫిల్లింగ్/ప్రొస్థెసిస్ దెబ్బతింది లేదా స్థానభ్రంశం చెందుతుంది",
        treatment: "ఇ-ఫైలింగ్",
        price: "₹ 350",
      },
      gujarati: {
        name: "મોકૂફી આપેલ કૃત્રિમ અંગ",
        description: "ભરણ/ કૃત્રિમ અંગ કાં તો નુકસાન અથવા વિસ્થાપિત છે",
        treatment: "ઈશ્વરી",
        price: "₹ 350",
      },
    },
    "inflammed / red gums": {
      color: "#661b40",
      english: {
        name: "Inflamed gum",
        description: "Red or swollen gums due to plaque & calculus",
        treatment: "Scaling",
        price: "₹ 150",
      },
      kannada: {
        name: "ಉರಿಯೂತದ ಗಮ್",
        description:
          "ಪ್ಲೇಕ್ ಮತ್ತು ಕಲನಶಾಸ್ತ್ರದ ಕಾರಣದಿಂದಾಗಿ ಕೆಂಪು ಅಥವಾ ಊದಿಕೊಂಡ ಒಸಡುಗಳು",
        treatment: "ಸ್ಕೇಲಿಂಗ್",
        price: "₹ 150",
      },
      marathi: {
        name: "हिरड्यांना सूज",
        description: "सुजलेली आणि लाल झालेली हिरडी",
        treatment: "दात स्वच्छ करणे",
        price: "₹ 150",
      },
      hindi: {
        name: "सूजे मसूड़े",
        description: "लाल या सूजे हुए मसूड़े",
        treatment: "दांतो की सफाई",
        price: "₹ 150",
      },
      tamil: {
        name: "வீக்கமடைந்த ஈறு",
        description:
          "பிளேக் மற்றும் கால்குலஸ் காரணமாக சிவப்பு அல்லது வீங்கிய ஈறுகள்",
        treatment: "அளவிடுதல்",
        price: "₹ 150",
      },
      telugu: {
        name: "ఎర్రబడిన గమ్",
        description: "ఫలకం & కాలిక్యులస్ కారణంగా ఎరుపు లేదా వాపు చిగుళ్ళు",
        treatment: "స్కేలింగ్",
        price: "₹ 150",
      },
      gujarati: {
        name: "સોજો",
        description: "તકતી અને કેલ્ક્યુલસને કારણે લાલ અથવા સોજો પે ums ા",
        treatment: "ઉન્માદ",
        price: "₹ 150",
      },
    },
    "peg lateral": {
      color: "#DEB6AB",
      english: {
        name: "Peg lateral",
        description: "Smaller than normal upper front tooth",
        treatment: "Cap/ Veneer",
        price: "₹ 920",
      },
      kannada: {
        name: "ಪೆಗ್ ಲ್ಯಾಟರಲ್",
        description: "ಸಾಮಾನ್ಯ ಮೇಲಿನ ಮುಂಭಾಗದ ಹಲ್ಲುಗಿಂತ ಚಿಕ್ಕದಾಗಿದೆ",
        treatment: "ಕ್ಯಾಪ್ / ವೆನಿರ್",
        price: "₹ 920",
      },
      marathi: {
        name: "छोटे दात",
        description: "समोरील छोटे दात",
        treatment: "विनियर / कॅप",
        price: "₹ 920",
      },
      hindi: {
        name: "छोटा दाँत",
        description: "सामने के छोटे दाँत",
        treatment: "विनियर/ कैप",
        price: "₹ 920",
      },
      tamil: {
        name: "ஆப்பு பக்கவாட்டு",
        description: "சாதாரண மேல் முன் பல்லை விட சிறியது",
        treatment: "தொப்பி / வெனீர்",
        price: "₹ 920",
      },
      telugu: {
        name: "పెగ్ పార్శ్వ",
        description: "సాధారణ ఎగువ ముందు దంతాల కంటే చిన్నది",
        treatment: "టోపీ/ వెనీర్",
        price: "₹ 920",
      },
      gujarati: {
        name: "પજવણી",
        description: "સામાન્ય ઉપલા આગળના દાંત કરતા નાના",
        treatment: "કોઇ",
        price: "₹ 920",
      },
    },
  },
};
