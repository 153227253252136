import React from "react";
import { Button, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getLanguage, getToken } from "../../store/rootReducer";
import { bookAppointment } from "../../services/rootService";
import { useHistory } from "react-router";
import { labelsMap } from "utils/i18n";

export default function Download() {
  const language = useSelector(getLanguage);
  const {
    DOWNLOAD_HEADING1,
    DOWNLOAD_HEADING2,
    EXIT_REPORT_BUTTON,
    BOOK_APPOINTMENT_BUTTON,
  } = labelsMap[language];
  const history = useHistory();
  const authToken = useSelector(getToken);

  const handleClickBookAppointment = async () => {
    try {
      // const result =
      await bookAppointment(authToken);
      history.push("/appointment");
    } catch (error) {
      console.log(error);
      history.push("/appointment");
    }
  };

  return (
    <div style={{ backgroundColor: "white", height: "100%" }}>
      <Grid
        container
        spacing={0}
        style={{
          height: "100%",
          padding: "20px",
        }}
      >
        <Grid item xs={12} md={12} lg={6} x1={6}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "90%",
              // backgroundColor: "red",
            }}
          >
            <div>
              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  alt="logo"
                  style={{ width: "12%", objectFit: "contain" }}
                  src={process.env.PUBLIC_URL + "/images/appointment-icon.png"}
                />
                &nbsp; &nbsp;
                
              </div> */}
              <div style={{ height: "2em" }}></div>
              <div
                className="heading-scan"
                style={{ textAlign: "center", width: "100%" }}
              >
                {DOWNLOAD_HEADING1}
              </div>
              <div style={{ height: "2em" }}></div>
              <div
                className="heading-download"
                style={{ textAlign: "center", width: "100%" }}
              >
                {DOWNLOAD_HEADING2}
              </div>
              <div style={{ height: "2em" }}></div>
              <div className="heading-download" style={{ textAlign: "center" }}>
                <img
                  alt="logo"
                  style={{ width: "40%", objectFit: "contain" }}
                  src={process.env.PUBLIC_URL + "/images/dd-qr.png"}
                />
              </div>
            </div>
            <div style={{ height: "2em" }}></div>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                {/* <div style={{ width: "50%" }}>
                  <Button
                    style={{ backgroundColor: "#052679", color: "white" }}
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      handleClickBookAppointment();
                    }}
                  >
                    <span style={{ fontSize: "1.5em" }}>
                      {BOOK_APPOINTMENT_BUTTON}
                    </span>
                  </Button>
                </div> */}
                <div style={{ width: "40%" }}>
                  <Button
                    style={{ borderColor: "#052679", color: "#052679" }}
                    fullWidth
                    variant="outlined"
                    onClick={() => {
                      history.entries = [];
                      history.index = -1;
                      history.push("/");
                    }}
                  >
                    <span style={{ fontSize: "1.5em" }}>
                      {EXIT_REPORT_BUTTON}
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={6} x1={6}>
          <div
            style={{
              padding: "4vh",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "start",
                backgroundColor: "#D9E9FC",
                overflowY: "scroll",
                height: "90%",
              }}
            >
              <div
                style={{
                  textAlign: "right",
                  padding: "1em",
                }}
              >
                <img
                  alt="logo"
                  style={{ width: "25%", objectFit: "contain" }}
                  src={process.env.PUBLIC_URL + "/images/logo_scano.png"}
                />
              </div>
              <div className="heading-download" style={{ textAlign: "center" }}>
                <img
                  alt="logo"
                  style={{ width: "100%", objectFit: "contain" }}
                  src={process.env.PUBLIC_URL + "/images/dd-app.png"}
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
