import React, { useState, useEffect, useRef } from "react";

const DiseaseInfo = ({ newDiseaseData }) => {
  const [playingVideoIndex, setPlayingVideoIndex] = useState(null);
  const [videoRefs, setVideoRefs] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const popupVideoRef = useRef(null);

  useEffect(() => {
    setVideoRefs((prevRefs) =>
      Array.from(
        { length: newDiseaseData.length },
        (_, index) => prevRefs[index] || React.createRef()
      )
    );
  }, [newDiseaseData]);

  const togglePlayPause = (index) => {
    setShowPopup(true);
    setPlayingVideoIndex(index);
  };

  const closePopup = () => {
    setShowPopup(false);
    if (popupVideoRef.current) {
      popupVideoRef.current.pause();
    }
  };

  useEffect(() => {
    if (showPopup && popupVideoRef.current) {
      popupVideoRef.current.play();
    }
  }, [showPopup]);

  const popupStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  };

  const videoStyle = {
    width: "80%",
    borderRadius: "15px",
    boxShadow: "0 5px 15px rgba(0,0,0,0.3)",
    objectFit: "contain",
  };

  return (
    <div>
      {showPopup && playingVideoIndex !== null && (
        <div style={popupStyle} onClick={closePopup}>
          <video
            ref={popupVideoRef}
            style={videoStyle}
            onClick={(e) => {
              e.stopPropagation();
              closePopup();
            }}
          >
            <source
              src={newDiseaseData[playingVideoIndex].videoUrl}
              type="video/mp4"
            />
          </video>
        </div>
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
        }}
      >
        {newDiseaseData.map((disease, index) => (
          <div
            key={index}
            className="video-player"
            style={{
              marginBottom: "20px",
              width: "300px",
              height: "300px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            <div style={{ position: "relative" }}>
              <video
                ref={videoRefs[index]}
                width="100%"
                onClick={() => togglePlayPause(index)}
              >
                <source src={disease.videoUrl} type="video/mp4" />
              </video>
            </div>
            <div
              style={{
                padding: "4px",
              }}
            >
              <span>{disease.diseaseName}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DiseaseInfo;
