import React from "react";
import { Button, Grid } from "@material-ui/core";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import {
  getAutoHeightAdjust,
  getCurrentAngle,
  getCurrentAngleIndex,
  getLanguage,
  getTestMode,
} from "../../store/rootReducer";
import { labelsMap } from "../../utils/i18n";

export default function Scan() {
  const history = useHistory();
  const currentAngle = useSelector(getCurrentAngle);
  const currentAngleIndex = useSelector(getCurrentAngleIndex);
  const language = useSelector(getLanguage);
  const testMode = useSelector(getTestMode);
  const autoHeight = useSelector(getAutoHeightAdjust);
  const {
    SCAN_HEADING_TEETH,
    SCAN_INSTRUCTIONS_MAP,
    SCAN_VIDEO_INSTRUCTIONS_MAP,
    SCAN_SUBMIT_BUTTON,
    ANGLE_LIST,
    VIDEO_MODE,
  } = labelsMap[language];
  // const [testMode] = useState(labelsMap.TEST_MODE);

  return (
    <div style={{ padding: 10, height: "100vh" }}>
      <Grid
        container
        spacing={0}
        style={{
          height: "100vh",
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          lg={6}
          x1={6}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <div
            style={{
              height: "10vh",
              textAlign: "right",
              padding: "1em",
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <img
              alt="logo"
              style={{ width: "200px", objectFit: "contain" }}
              src={process.env.PUBLIC_URL + "/images/logo_scano.png"}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              {/* <img
                alt="logo"
                style={{ width: "25%", objectFit: "contain" }}
                src={process.env.PUBLIC_URL + `/images/${currentAngle}.png`}
              /> */}
              {testMode === "VIDEO" ? (
                <div className="heading-scan" style={{ textAlign: "left" }}>
                  {VIDEO_MODE}
                </div>
              ) : (
                <div className="heading-scan" style={{ textAlign: "left" }}>
                  {ANGLE_LIST[currentAngleIndex]}
                  &nbsp;{SCAN_HEADING_TEETH}
                </div>
              )}
            </div>

            <div style={{ textAlign: "left", padding: "2em" }}>
              <div style={{ height: "2em" }}></div>
              {testMode === "VIDEO" ? (
                <div className="heading2" style={{ textAlign: "left" }}>
                  <ol>
                    {SCAN_VIDEO_INSTRUCTIONS_MAP["data"].map(
                      (itemInner, index) => (
                        <li key={index}>{itemInner}</li>
                      )
                    )}
                  </ol>
                </div>
              ) : (
                <div className="heading2" style={{ textAlign: "left" }}>
                  <ol>
                    {SCAN_INSTRUCTIONS_MAP[currentAngle].map(
                      (itemInner, index) => (
                        <li key={index}>{itemInner}</li>
                      )
                    )}
                  </ol>
                </div>
              )}

              <div style={{ height: "2em" }}></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <div style={{ width: "50%" }}>
                  <Button
                    style={{ backgroundColor: "#052679", color: "white" }}
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      if (autoHeight) {
                        history.push("/autoHeight");
                      } else {
                        history.push("/scanAngles");
                      }
                    }}
                  >
                    <span style={{ fontSize: "1.5em" }}>
                      {SCAN_SUBMIT_BUTTON}
                    </span>
                  </Button>
                </div>
              </div>
            </div>
            <div style={{ height: "2em" }}></div>
          </div>
        </Grid>
        <Grid
          className="slider-container"
          item
          xs={12}
          md={12}
          lg={6}
          x1={6}
          style={{}}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "95%",
              }}
            >
              <video muted width="100%" loop autoPlay>
                <source
                  src={
                    "https://djx8i6khfnu04.cloudfront.net/video-media/kiosk_guide_rishika.mp4"
                  }
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
