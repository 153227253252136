export const OPEN_KEYBOARD = "OPEN_KEYBOARD";
export const CLOSE_KEYBOARD = "CLOSE_KEYBOARD";
export const UPDATE_INPUT_VALUE = "UPDATE_INPUT_VALUE";
export const BUTTON_PRESS = "BUTTON_PRESS"; // New action type

export function openKeyboard(currentInput, value) {
  return {
    type: OPEN_KEYBOARD,
    payload: { currentInput, value },
  };
}

export function closeKeyboard() {
  return {
    type: CLOSE_KEYBOARD,
  };
}

export function updateInputValue(value) {
  return {
    type: UPDATE_INPUT_VALUE,
    payload: { value },
  };
}

export function buttonPress(button) {
  return {
    type: BUTTON_PRESS,
    payload: { button },
  };
}
