import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import CustomSnackBar from "../../components/CustomSnackBar";
import {
  bypassLogin,
  bypassOtp,
  getOtp,
  signUp,
  submitOtp,
  testInit,
  updateUser,
} from "../../services/rootService";
import {
  changeLanguage,
  getLanguage,
  setTestId,
  setToken,
  setUserDetails,
  getTestMode,
  setTestMode,
  getKioskId,
  getBypassOTP,
  getBypassLogin,
  getPartnerToken,
  getLanguageList,
  setPatientNumber,
} from "../../store/rootReducer";
import { labelsMap } from "../../utils/i18n";
import CircularProgress from "@mui/material/CircularProgress";
import ScreenKeyboard from "containers/keyboard/screenKeyboard";
import { getLocation } from "../../store/rootReducer";
import {
  openKeyboard,
  closeKeyboard,
  updateInputValue,
} from "containers/keyboard/action";
import { maxLengths } from "containers/keyboard/reducer";
export default function Login() {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [token, setTempToken] = useState("");
  const [otpSessionId, setOtpSessionId] = useState("");
  const [isNameValid, setIsNameValid] = useState(true);
  const [isMobileValid, setIsMobileValid] = useState(true);
  const [isOtpMode, setIsOtpMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSubmitOtp, setIsLoadingSubmitOtp] = useState(true);
  const [isLoadingResendOtp, setIsLoadingResendOtp] = useState(true);
  const [snackBarData, setSnackBarData] = React.useState({
    open: false,
    severity: "success",
    message: "",
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const language = useSelector(getLanguage);
  const testMode = useSelector(getTestMode);
  const kioskId = useSelector(getKioskId);
  const bypassOTP = useSelector(getBypassOTP);
  const isBypassLogin = useSelector(getBypassLogin);
  const partnerToken = useSelector(getPartnerToken);
  const languageList = useSelector(getLanguageList);
  const {
    LOGIN_HEADING,
    LOGIN_SUBHEADING,
    LOGIN_INPUT_NAME,
    LOGIN_INPUT_MOBILE,
    LOGIN_INPUT_OTP,
    LOGIN_BUTTON_SEND_OTP,
    LOGIN_BUTTON_SUBMIT_OTP,
    LOGIN_BUTTON_RESEND_OTP,
    LOGIN_BUTTON_EDIT_NUMBER,
    LOGIN_MESSAGE_OTP_SENT,
    LOGIN_MESSAGE_INVALID_OTP,
    LOGIN_MESSAGE_OTP_ERROR,
    LOGIN_MESSAGE_INVALID_DATA,
    LOGIN_MESSAGE_OTP_SUBMIT_ERROR,
  } = labelsMap[language];
  const regexMap = {
    name: "^(?=.*\\S).+$",
    mobile: "^(\\d *){10}$",
  };
  const location = useSelector(getLocation);

  const keyboardState = useSelector((state) => state.keyboard); // Access the keyboard state from your Redux store

  useEffect(() => {
    // Perform validation whenever keyboardState changes
    if (keyboardState.isOpen) {
      if (keyboardState.currentInput === "name") {
        setIsNameValid(validateValue(regexMap["name"], keyboardState.value));
      } else if (keyboardState.currentInput === "mobile") {
        setIsMobileValid(
          validateValue(regexMap["mobile"], keyboardState.value)
        );
      }
    }
  }, [keyboardState]);

  const onFocus = (event) => {
    dispatch(openKeyboard(event.target.id, event.target.value));
  };
  const onBlur = (event) => {
    // Update the corresponding state variables
    if (event.target.id === "name") {
      setName(event.target.value);
    } else if (event.target.id === "mobile") {
      setMobile(event.target.value);
    } else if (event.target.id === "otp") {
      handleOtpChange(event);
    } else if (event.target.id === "token") {
      handleTokenChange(event);
    }

    dispatch(closeKeyboard());
  };
  const validateValue = (regex, value) => {
    const reg = new RegExp(regex);
    const result = reg.test(value);
    return result;
  };

  const validateForm = () => {
    return !!(isNameValid && isMobileValid);
  };
  const handleTokenChange = (event) => {
    const { value } = event.target;
    setTempToken(value);
  };

  const handleNameChange = (event) => {
    const { value } = event.target;
    if (value.length <= maxLengths["name"]) {
      setName(value);
    } else {
      setName(value.slice(0, maxLengths["name"]));
    }
    console.log("Checkingh", value);
    setIsNameValid(validateValue(regexMap["name"], value));
  };

  const handleMobileChange = (event) => {
    const { value } = event.target;
    if (value.length <= maxLengths["mobile"]) {
      setMobile(value);
    } else {
      setMobile(value.slice(0, maxLengths["mobile"]));
    }
    setIsMobileValid(validateValue(regexMap["mobile"], value));
  };

  const handleOtpChange = (event) => {
    const { value } = event.target;
    if (value.length <= maxLengths["otp"]) {
      setOtp(value);
    } else {
      setOtp(value.slice(0, maxLengths["otp"]));
    }
  };

  useEffect(() => {
    console.log("dfd", keyboardState);
    switch (keyboardState.currentInput) {
      case "name":
        handleVirtualNameChange();
        break;
      case "mobile":
        handleVirtualMobileChange();
        break;
      case "otp":
        handleVirtualOtpChange();
        break;
      case "token":
        handleVirtualTokenChange();
        break;
    }
  }, [keyboardState.value]);

  const handleVirtualTokenChange = () => {
    const value = keyboardState.value;
    console.log("Setting", value);
    setTempToken(value);
  };

  const handleVirtualNameChange = () => {
    const value = keyboardState.value;
    if (value.length <= maxLengths["name"]) {
      setName(value);
    } else {
      setName(value.slice(0, maxLengths["name"]));
    }
    setIsNameValid(validateValue(regexMap["name"], value));
  };

  const handleVirtualMobileChange = () => {
    const value = keyboardState.value;
    if (value.length <= maxLengths["mobile"]) {
      setMobile(value);
    } else {
      setMobile(value.slice(0, maxLengths["mobile"]));
    }
    setIsMobileValid(validateValue(regexMap["mobile"], value));
  };

  const handleVirtualOtpChange = () => {
    const value = keyboardState.value;
    if (value.length <= maxLengths["otp"]) {
      setOtp(value);
    } else {
      setOtp(value.slice(0, maxLengths["otp"]));
    }
  };

  const handleSubmitClick = async (event) => {
    if (isBypassLogin) {
      const result = await bypassLogin(partnerToken);
      dispatch(setToken(result.data.token));
      dispatch(setPatientNumber(token));
      // const updateUserData =
      await updateUser(result.data.token, name, kioskId);
      const initTestResult = await testInit(result.data.token, kioskId);
      setIsLoadingSubmitOtp(true);
      if (initTestResult && initTestResult.data) {
        const { tid } = initTestResult.data;
        if (tid) {
          dispatch(setTestId(tid));
          history.push("/scan");
        }
      }
    }
    if (validateForm()) {
      setIsLoading(false);
      setIsLoadingResendOtp(false);
      try {
        if (bypassOTP) {
          const result = await bypassOtp(name, mobile, kioskId);
          dispatch(setToken(result.data.token));
          dispatch(setUserDetails({ name, mobile }));
          // const updateUserData =
          await updateUser(result.data.token, name, kioskId);
          const initTestResult = await testInit(result.data.token, kioskId);
          setIsLoadingSubmitOtp(true);
          if (initTestResult && initTestResult.data) {
            const { tid } = initTestResult.data;
            if (tid) {
              dispatch(setTestId(tid));
              history.push("/scan");
            }
          }
        } else {
          const result = await getOtp(mobile);

          if (result && result.data) {
            const { sessionID } = result.data;
            if (sessionID) {
              setOtpSessionId(sessionID);
            }
          }
          setIsOtpMode(true);
          setIsLoading(true);
          setIsLoadingResendOtp(true);
          setSnackBarData({
            open: true,
            severity: "success",
            message: LOGIN_MESSAGE_OTP_SENT,
          });
        }
      } catch (error) {
        setIsLoading(true);
        setIsLoadingResendOtp(true);
        setSnackBarData({
          open: true,
          severity: "error",
          message: LOGIN_MESSAGE_OTP_ERROR,
        });
      }
    } else {
      setIsLoading(true);
      setSnackBarData({
        open: true,
        severity: "error",
        message: LOGIN_MESSAGE_INVALID_DATA,
      });
    }
  };

  const handleSubmitOtpClick = async () => {
    if (otp.length !== 6) {
      setSnackBarData({
        open: true,
        severity: "error",
        message: LOGIN_MESSAGE_INVALID_OTP,
      });
    } else {
      try {
        setIsLoadingSubmitOtp(false);
        const result = await submitOtp(mobile, otp, otpSessionId);
        if (result && result.data) {
          let finalToken = "";
          const { isValid, token, existingUser } = result.data;
          if (isValid) {
            if (!existingUser) {
              const resultSignUp = await signUp(name, mobile, location);
              if (resultSignUp && resultSignUp.data) {
                const { token } = resultSignUp.data;
                finalToken = token;
              }
            } else {
              finalToken = token;
            }
            dispatch(setToken(finalToken));
            dispatch(setUserDetails({ name, mobile }));
            // const updateUserData =
            await updateUser(finalToken, name, kioskId);
            const initTestResult = await testInit(finalToken, kioskId);
            setIsLoadingSubmitOtp(true);
            if (initTestResult && initTestResult.data) {
              const { tid } = initTestResult.data;
              if (tid) {
                dispatch(setTestId(tid));
                history.push("/scan");
              }
            }
          }
        }
      } catch (error) {
        setIsLoadingSubmitOtp(true);
        setSnackBarData({
          open: true,
          severity: "error",
          message: LOGIN_MESSAGE_OTP_SUBMIT_ERROR,
        });
      }
    }
  };

  return (
    <>
      <div style={{ padding: 10, height: "100%" }}>
        <Grid
          container
          style={{
            justifyContent: "center",
            flexDirection: "reverse",
            height: "100%",
          }}
        >
          <Grid item xs={12} md={6} lg={6} x1={6}>
            <div
              style={{
                height: "5vh",
                display: "flex",
                flexDirection: "row",
                alignItems: "end",
                justifyContent: "end",
              }}
            >
              {/* <Select
                labelId="mode-label"
                id="mode-select"
                value={testMode}
                label="Test Mode"
                onChange={(event) => {
                  if (event && event.target && event.target.value) {
                    dispatch(setTestMode(event.target.value));
                  }
                }}
              >
                <MenuItem value={"VIDEO"}>Video</MenuItem>
                <MenuItem value={"IMAGE"}>Image</MenuItem>
              </Select> */}
              &nbsp; &nbsp;
              <Select
                labelId="language-label"
                id="language-select"
                value={language}
                label="Language"
                onChange={(event) => {
                  if (event && event.target && event.target.value) {
                    dispatch(changeLanguage(event.target.value));
                  }
                }}
              >
                <MenuItem value={"english"}>English</MenuItem>
                <MenuItem value={"hindi"}>हिंदी</MenuItem>
                {languageList.map((languageListItemInner) => {
                  return (
                    <MenuItem value={languageListItemInner?.value}>
                      {languageListItemInner?.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div
              style={{
                height: "80%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <img
                alt="logo"
                style={{ width: "25%", objectFit: "contain" }}
                src={process.env.PUBLIC_URL + "/images/logo_scano.png"}
              />
              <div className="heading1">{LOGIN_HEADING}</div>
              <div className="heading2">{LOGIN_SUBHEADING}</div>
              <div style={{ height: "2em" }}></div>
              {isBypassLogin ? (
                <div style={{ width: "50%" }}>
                  <FormControl fullWidth sx={{ m: 2 }}>
                    <TextField
                      id="token"
                      label="Token"
                      variant="outlined"
                      value={token}
                      onChange={handleTokenChange}
                      onFocus={onFocus}
                      onBlur={onBlur}
                    />
                  </FormControl>
                </div>
              ) : (
                <>
                  {!isOtpMode ? (
                    <>
                      <div style={{ width: "50%" }}>
                        <FormControl fullWidth sx={{ m: 2 }}>
                          <TextField
                            id="name"
                            label={LOGIN_INPUT_NAME}
                            type="tel"
                            variant="outlined"
                            value={name}
                            onChange={handleNameChange}
                            error={!isNameValid}
                            onFocus={onFocus}
                            onBlur={onBlur}
                          />
                        </FormControl>
                      </div>
                      <div style={{ height: "2em" }}></div>
                      <div style={{ width: "50%" }}>
                        <FormControl fullWidth sx={{ m: 2 }}>
                          <TextField
                            id="mobile"
                            label={LOGIN_INPUT_MOBILE}
                            type={"tel"}
                            variant="outlined"
                            value={mobile}
                            onChange={handleMobileChange}
                            error={!isMobileValid}
                            onFocus={onFocus}
                            onBlur={onBlur}
                          />
                        </FormControl>
                      </div>
                    </>
                  ) : (
                    <>
                      <div style={{ height: "2em" }}></div>
                      <div style={{ width: "50%" }}>
                        <FormControl fullWidth sx={{ m: 2 }}>
                          <TextField
                            id="otp"
                            type={"tel"}
                            label={LOGIN_INPUT_OTP}
                            variant="outlined"
                            value={otp}
                            onChange={handleOtpChange}
                            onFocus={onFocus}
                            onBlur={onBlur}
                          />
                        </FormControl>
                      </div>
                    </>
                  )}
                </>
              )}

              <div style={{ height: "2em" }}></div>
              {!isOtpMode ? (
                <div style={{ width: "50%" }}>
                  <Button
                    style={{
                      backgroundColor: "#052679",
                      color: "white",
                      opacity: validateForm() ? 1 : 0.5, // Modify opacity based on the result of validForm()
                    }}
                    variant="contained"
                    fullWidth
                    onClick={handleSubmitClick}
                    disabled={!validateForm() || isLoading === false} // Disable the button if validForm() is false or isLoading is false
                  >
                    <span style={{ fontSize: "1.5em" }}>
                      {isLoading ? (
                        isBypassLogin || bypassOTP ? (
                          "Start"
                        ) : (
                          LOGIN_BUTTON_SEND_OTP
                        )
                      ) : (
                        <CircularProgress color="inherit" />
                      )}
                    </span>
                  </Button>
                </div>
              ) : (
                <>
                  <div style={{ width: "50%" }}>
                    <Button
                      style={{ backgroundColor: "#052679", color: "white" }}
                      variant="contained"
                      fullWidth
                      onClick={handleSubmitOtpClick}
                      disabled={isLoadingSubmitOtp === false}
                    >
                      <span style={{ fontSize: "1.5em" }}>
                        {isLoadingSubmitOtp ? (
                          LOGIN_BUTTON_SUBMIT_OTP
                        ) : (
                          <CircularProgress color="inherit" />
                        )}
                      </span>
                    </Button>
                  </div>
                  <div style={{ height: "2em" }}></div>
                  <div style={{ width: "50%" }}>
                    <Button
                      style={{ borderColor: "#052679", color: "#052679" }}
                      variant="outlined"
                      fullWidth
                      onClick={handleSubmitClick}
                      disabled={isLoadingResendOtp === false}
                    >
                      <span style={{ fontSize: "1em" }}>
                        {isLoadingResendOtp ? (
                          LOGIN_BUTTON_RESEND_OTP
                        ) : (
                          <CircularProgress color="inherit" />
                        )}
                      </span>
                    </Button>
                  </div>
                  <div style={{ height: "2em" }}></div>
                  <div style={{ width: "50%" }}>
                    <Button
                      style={{ borderColor: "#052679", color: "#052679" }}
                      variant="outlined"
                      fullWidth
                      onClick={() => {
                        setOtp("");
                        setIsOtpMode(false);
                      }}
                    >
                      <span style={{ fontSize: "1em" }}>
                        {LOGIN_BUTTON_EDIT_NUMBER}
                      </span>
                    </Button>
                  </div>
                </>
              )}
            </div>
          </Grid>
          {/* <Grid
            className="slider-container"
            item
            xs={12}
            md={12}
            lg={6}
            x1={6}
            style={{ display: "flex" }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ width: "95%" }}>
                <video
                  key={language + "-video"}
                  muted
                  width="100%"
                  loop
                  autoPlay
                >
                  <source
                    src={"./videos/" + language + ".mp4"}
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </Grid> */}
        </Grid>
        <CustomSnackBar setSnackBarData={setSnackBarData} {...snackBarData} />
      </div>
      <ScreenKeyboard />
    </>
  );
}
