import React, { useState, useRef, useEffect } from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import "./screenKeyboard.css";
import { useSelector, useDispatch } from "react-redux";
import { buttonPress, closeKeyboard } from "./action"; // import actions

const ScreenKeyboard = () => {
  const [layoutName, setLayoutName] = useState("default");

  const layout = useRef({
    default: [
      "1 2 3 4 5 6 7 8 9 0",
      "q w e r t y u i o p",
      "a s d f g h j k l",
      "{shift} z x c v b n m , .",
      "?123 {space} \u232B",
    ],
    shift: [
      "! @ # $ % ^ & * ( )",
      "Q W E R T Y U I O P",
      "A S D F G H J K L",
      "{shift} Z X C V B N M , .",
      "?123 {space} \u232B",
    ],
    numSymbols: [
      "1 2 3 4 5 6 7 8 9 0",
      "- / : ; ( ) $ & @",
      "! ? . , ' \"",
      "{shift} + = < > # ",
      "ABC {space} \u232B",
    ],
    numbers: ["1 2 3", "4 5 6", "7 8 9", "- 0 \u232B"],
  });

  useEffect(() => {
    const numOfRows = layout.current[layoutName].length;
    const rowHeightPercentage = 100 / numOfRows;
    document.documentElement.style.setProperty(
      "--row-height-percentage",
      `${rowHeightPercentage}%`
    );
  }, [layoutName]);

  const keyboard = useRef();
  const dispatch = useDispatch();
  const keyboardState = useSelector((state) => state.keyboard);

  const clickSound = new Audio(
    process.env.PUBLIC_URL + "/audios/mixkit-modern-click-box-check-1120.wav"
  );

  const onChange = (input, event) => {
    if (event) {
      event.preventDefault();
    }
  };

  const onKeyPress = (button, event) => {
    if (event) {
      event.preventDefault();
    }
    clickSound.play();

    // Special keys handling
    if (button === "{shift}" || button === "{lock}") {
      handleShift();
      return;
    }

    if (button === "?123") {
      handleNumSymbols();
      return;
    }

    if (button === "ABC") {
      handleDefault();
      return;
    }

    dispatch(buttonPress(button));
  };

  const handleShift = () => {
    setLayoutName(layoutName === "default" ? "shift" : "default");
  };

  const handleNumSymbols = () => {
    setLayoutName("numSymbols");
  };

  const handleDefault = () => {
    setLayoutName("default");
  };

  useEffect(() => {
    if (
      keyboardState.currentInput === "mobile" ||
      keyboardState.currentInput === "otp" ||
      keyboardState.currentInput === "token"
    ) {
      setLayoutName("numbers");
    } else {
      setLayoutName("default");
    }
  }, [keyboardState.currentInput]);

  return keyboardState.isOpen ? (
    <div
      className="keyboard-wrapper"
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      <Keyboard
        keyboardRef={(r) => (keyboard.current = r)}
        layoutName={layoutName}
        layout={layout.current}
        onChange={onChange}
        onKeyPress={onKeyPress}
        inputValue={keyboardState.value}
      />
    </div>
  ) : null;
};

export default ScreenKeyboard;
