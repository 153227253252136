import React, { useState, useEffect } from "react";
import socketService from "services/socketService";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { openKeyboard } from "containers/keyboard/action";

import { useDispatch, useSelector } from "react-redux";
import { closeKeyboard } from "containers/keyboard/action";
import { Typography } from "@material-ui/core";
import ScreenKeyboard from "containers/keyboard/screenKeyboard";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import CustomSnackBar from "components/CustomSnackBar";

// Styles for full page UI
const useStyles = makeStyles((theme) => ({
  fullPage: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    margin: theme.spacing(1),
    width: "25ch",
  },
  selectionRow1: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  selectionItem: {
    width: "80%",
  },
}));

const WifiConfig = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [availableNetworks, setAvailableNetworks] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const keyboardState = useSelector((state) => state.keyboard);
  const [message, setMessage] = useState("");
  const [snackBarData, setSnackBarData] = React.useState({
    open: false,
    severity: "success",
    message: "",
  });
  const [currentWifi, setCurrentWifi] = useState("");
  const history = useHistory();
  useEffect(() => {
    switch (keyboardState.currentInput) {
      case "password":
        handleVirtualPasswordChange();
        break;
    }
  }, [keyboardState.value]);
  const handlePasswordChange = (event) => {
    const { value } = event.target;
    setPassword(value);
  };

  const handleGoback = () => {
    history.push("/");
  };

  const handleVirtualPasswordChange = (event) => {
    setPassword(keyboardState.value);
  };

  useEffect(() => {
    getAvailableNetworks();
    getCurrentWifiDetails();
  }, []);

  const handleRefresh = () => {
    getAvailableNetworks();
    getCurrentWifiDetails();
  };

  const getAvailableNetworks = () => {
    setShowLoader(true);
    socketService
      .getWifiNetworksAsync()
      .then((networks) => {
        setAvailableNetworks(networks.filter((network) => network?.length));
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const getCurrentWifiDetails = () => {
    setShowLoader(true);
    socketService
      .getCurrentWifiDetailsAsync()
      .then((details) => {
        setCurrentWifi(details[0] || "");
      })
      .finally(() => {
        setShowLoader(false);
      });
  };
  const handleConnect = () => {
    if (!selectedNetwork?.length || !password?.length) {
      setSnackBarData({
        open: true,
        severity: "error",
        message: "Please select wifi network and provide password",
      });
      return;
    }
    socketService
      .connectWifiAsync(selectedNetwork, password)
      .then((result) => {
        console.log("Result", result);
        if (result) {
          setSnackBarData({
            open: true,
            severity: "success",
            message: "Network Connected",
          });
          history.push("/");
        } else {
          setSnackBarData({
            open: true,
            severity: "error",
            message: "Network error, please try again",
          });
        }
      })
      .catch((error) => {
        console.log("Error while connecting to WiFi", error);
        setSnackBarData({
          open: true,
          severity: "error",
          message: "Network Error",
        });
      });
  };

  const onFocus = (event) => {
    dispatch(openKeyboard(event.target.id));
  };
  const onBlur = (event) => {
    dispatch(closeKeyboard());
  };
  return showLoader ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <div className={classes.fullPage}>
      <Typography variant="h4" gutterBottom>
        WiFi Configuration
      </Typography>
      <Typography variant="body1">
        Select a network and enter the password to connect.
      </Typography>
      <Typography variant="body1">
        <strong>Current Wifi: {currentWifi}</strong>
      </Typography>
      <FormControl className={classes.formControl} fullWidth variant="outlined">
        <InputLabel id="network-select-label">Select Network</InputLabel>
        <div className={classes.selectionRow}>
          <Select
            className={classes.selectionItem}
            labelId="network-select-label"
            value={selectedNetwork}
            onChange={(e) => setSelectedNetwork(e.target.value)}
            // onClick={getAvailableNetworks}
            label="Select Network"
          >
            {availableNetworks.map((network) => (
              <MenuItem key={network} value={network}>
                {network}
              </MenuItem>
            ))}
          </Select>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleRefresh}
            style={{ margin: "16px" }}
          >
            Refresh
          </Button>
        </div>
      </FormControl>
      <TextField
        className={classes.textField}
        fullWidth
        id="password"
        variant="outlined"
        label="Password"
        type={showPassword ? "text" : "password"}
        onFocus={onFocus}
        onChange={handlePasswordChange}
        onBlur={onBlur}
        value={password}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Button
        variant="contained"
        color="secondary"
        onClick={handleConnect}
        style={{ margin: "16px" }}
      >
        Connect
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleGoback}
        style={{ margin: "16px" }}
      >
        Go Back
      </Button>
      {message && (
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ margin: "8px" }}
        >
          {message}
        </Typography>
      )}
      <ScreenKeyboard />
      <CustomSnackBar setSnackBarData={setSnackBarData} {...snackBarData} />
    </div>
  );
};

export default WifiConfig;
