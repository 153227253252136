import React from "react";
import styles from "./manual-height.module.css";
import { useSelector } from "react-redux";
import { getLanguage } from "store/rootReducer";
import { labelsMap } from "utils/i18n";
import socketService from "services/socketService";

export default function ManualAdjustButtons({ showTextGuide = true }) {
  const language = useSelector(getLanguage);
  const { CLICK_ADJUST } = labelsMap[language];

  const handleTouchStart = (direction) => {
    if (direction === "up") {
      socketService.motorUp();
    } else {
      socketService.motorDown();
    }
  };

  const handleTouchEnd = () => {
    socketService.motorStop();
  };

  return (
    <>
      <div className={styles.arrows}>
        <img
          draggable={false}
          src={process.env.PUBLIC_URL + "/images/upper-arrow.png"}
          alt="Upper Arrow"
          onTouchStart={() => handleTouchStart("up")}
          onTouchEnd={handleTouchEnd}
          className={styles.touchable}
        />
        <div style={{ height: "40px" }}></div>
        <img
          draggable={false}
          src={process.env.PUBLIC_URL + "/images/lower-arrow.png"}
          alt="Lower Arrow"
          onTouchStart={() => handleTouchStart("down")}
          onTouchEnd={handleTouchEnd}
          className={styles.touchable}
        />
        {showTextGuide && (
          <div className={styles["manual-text-guide"]}>
            <div>^</div>
            <div>{CLICK_ADJUST}</div>
          </div>
        )}
      </div>
    </>
  );
}
